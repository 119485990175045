import { Base64 } from "js-base64";

let domain = process.env.VUE_APP_DOMAIN
let location = window.location.hostname
let production = false
if(domain == location){
    production = true
}
export const encodeStorage = {
    setItem: async function(key, val){
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        val = JSON.stringify(val)

        val = Base64.encode(val)
        localStorage.setItem(key, val)
    }
    , getItem: function (key){
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        let t = localStorage.getItem(key)
        if(t){
            t = JSON.parse(Base64.decode(t))
        }

        return t
    }
    , removeItem: async function(key){
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key
        localStorage.removeItem(key)
    }
    , removeSessionCertfc: async function(){
        let key = 'certfc'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key
        sessionStorage.removeItem(key)
    }
    , setSessionCertfc: async function(data){
        let key = 'certfc'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key
        sessionStorage.setItem(key, Base64.encode(JSON.stringify(data)))
    }
    , getSessionCertfc: async function(){
        let key = 'certfc'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        let t = sessionStorage.getItem(key)
        if(t){
            t = JSON.parse(Base64.decode(t))
        }
        return t
    }
    , getSessionToken: function(){
        let key = 'certfc'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        let t = sessionStorage.getItem(key)
        if(t){
            t = JSON.parse(Base64.decode(t))
            t = t.session_token
        }
        return t
    }
    , setSessionToken: async function(token){
        let key = 'certfc'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        let t = sessionStorage.getItem(key)

        if(t){
            t = JSON.parse(Base64.decode(t))
            t.session_token = token
            await this.setSessionCertfc(t)
        }
    }

    , setSessionAt: async function(data){
        let key = 'AT'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        if(typeof data != 'string'){
            data = JSON.stringify(data)
        }
        data = Base64.encode(data)

        sessionStorage.setItem(key, data)
    }
    , getSessionAt: function(){
        let key = 'AT'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        let t = sessionStorage.getItem(key)
        if(t){
            t = JSON.parse(Base64.decode(t))
        }

        return t
    }
    , removeSessionAt: async function(){
        let key = 'AT'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        sessionStorage.removeItem(key)
        localStorage.removeItem(key)
    }
    , setSessionVersion: async function(version){
        let key = 'v'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        sessionStorage.setItem(key, version)
    }
    , getSessionVersion: function(){
        let key = 'v'
        if(production) key = Base64.encode(process.env.VUE_APP_NAME + '_' + key)
        else key = process.env.VUE_APP_NAME + '_' + key

        let t = sessionStorage.getItem(key)

        return t
    }
}