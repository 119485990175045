<template>
	<PopupLayer
		v-if="is_event"
		style="z-index: 99999"
	>
		<template
			v-slot:body
		>
			<div
				class="event_pop full_layer"
			>
				<div
					class="full_layer_inner "
					style="max-width: 600px; background-color: #130A43; border-radius: 30px; "
				>
					<div
						@click="toDetail"
						class="cursor-pointer overflow-y-auto"
						style="max-height: 800px; "
					>
						<div
							v-if="!item.img"
							class="bg-white text-center pa-20"
						>{{ item.name }}</div>
						<img
							:src="item.img" :alt="item.name"
							@error="$bus.$emit('onErrorProfileImage', $event)"
						/>
					</div>
					<div class="event_btnarea pt-20">
						<div class="evchk" style="flex:1">
							<input v-model="is_today" type="checkbox" id="ev_chk" class="hide">
							<label for="ev_chk" class="evclose" aria-label="오늘하루안보기"></label>

						</div>
						<button
							@click="ban"
							style="flex:1"
						>
							<img :src="require('@/assets/image/event/mevent3.png')" alt="닫기버튼">
						</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'event'
	, components: {PopupLayer}
	, props: ['user']
	, data: function(){
		return {
			is_event: false
			, is_today: true
			, is_event_detail: false
			, items_event: []
			, item: {

			}
		}
	}
	, computed: {
	}
	, methods: {
		ban: function(){
			if(this.is_today){
				localStorage.setItem('system_event', this.$date.getToday('-'))
			}
			this.is_event = false
		}
		, getSystemEvent: async function(){
			let event_list = this.$codes.event_list

			let domain = process.env.VUE_APP_DOMAIN
			let domain_m = process.env.VUE_APP_DOMAIN_M
			let domain_stg = process.env.VUE_APP_DOMAIN_STG
			let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV
			let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
			let location = window.location.href

			// 운영계 API 호출
			if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
				this.items_event = event_list['live']

				// 검증계 API 호출
			}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
				this.items_event = event_list['stg']

				// 개발계 API 호출
			}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
				this.items_event = event_list['dev']
				// 로컬 API 호출
			}else{
				this.items_event = event_list['dev']
			}

			if((this.$route.name == 'main' || this.$route.name == 'mafia044' || this.$route.name == 'index'|| this.$route.name == 'cartel' || this.$route.name == 'home') && document.location.href.indexOf('#detail') < 0){

				let system_event = localStorage.getItem('system_event')
				let today = this.$date.getToday('-')

				if(system_event != today){
					for(let i = 0; i < this.items_event.length; i++){
						let event = this.items_event[i]
						if(event.is_use && event.sDate <= today && event.eDate >= today){
							this.item = event
							this.is_event = true
							break
						}
					}
				}else{
					this.is_event = false
				}
			}else{
				this.is_event = false
			}
			this.is_today = this.$route.path.indexOf('auth') > -1 ? false : true
		}
		, toDetail: function(){
			//let router = this.$router.resolve({ name: 'EventDetail', params: {idx: this.item.idx}})
			// document.location.href = router.href

			if(!this.item.not_link){
				this.$bus.$emit('to', { name: 'EventDetail', params: { idx: this.item.idx }})
			}
		}
	}
	, created() {
		this.getSystemEvent()
	}
	, watch: {
		$route(){
			this.getSystemEvent()
		}
	}
}
</script>