
// import axios from 'axios'
export const common = {

    isMobile: function(){
        let user = navigator.userAgent;
        let w_type = 'web'
        w_type = 'CM00500002'
        if(user.toLowerCase().indexOf('iphone') > -1 || user.toLowerCase().indexOf('ipad') > -1 || user.toLowerCase().indexOf('ipod') > -1 || user.toLowerCase().indexOf('android') > -1){
            w_type = 'mobile'
            w_type = 'CM00500003'
        }

        return w_type
    }
    , getMobile: function(){
        let user = navigator.userAgent;
        let w_type = 'web'
        w_type = 'CM00500002'
        if(user.toLowerCase().indexOf('iphone') > -1 || user.toLowerCase().indexOf('ipad') > -1 || user.toLowerCase().indexOf('ipod') > -1){
            w_type = 'ios'
        }else if(user.toLowerCase().indexOf('android') > -1){
            w_type = 'android'
        }

        return w_type
    }
    , getOsCode: function(){
        let t = ''
        try{
            window.android.postMessage('getOsCode')
            if(this.getMobile() == 'android'){
                t = 'AP00100001'
            }else{
                t = 'AP00100002'
            }
        }catch (e){
            t = ''
        }
        return t
    }
    , getPaymentCode: function(){
        let t = 'PM00300001'
        try{

            window.android.postMessage('')

            if(this.getMobile() == 'android') {
                t = 'PM00300004'
            }else{
                t = 'PM00300001'
            }
        }catch(e){
            t = 'PM00300001'
        }

        return t
    }
    , getIp: async function(){
        return ''
        /*
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            if(response){
                throw response.data.ip
            }else{
                throw 'error'
            }
        } catch (error) {
            console.error(error);
            return 'error'
        }
        */
    }
}