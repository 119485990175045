<template>
	<div
		class="under-line"
	>
		<div class="cartel-info position-relative">

			<img
				ref="bg_cover"
				:src="cartel.cartl_web_img_url ? cartel.cartl_web_img_url : cartel.cartl_img_url"
				class="position-absolute"
				style="z-index: -1"
			/>
			<div
				class="position-absolute-full"
				:style="bg_cover"
				style="z-index: 997; left: 0; top: 0; height: 100%"
			></div>

			<div class="position-absolute-full opacity-1" style="z-index: 998; background-color: rgba(0, 0, 0, 0.65)"></div>

			<div class="color-white pa-30 cartel-info position-relative" style="text-shadow: 0px 0px 2px rgba(0, 0, 0, 1); z-index: 999">
				<div class="full-height flex-row" style="width: 1200px; margin: 0 auto">
					<div class="flex-column justify-end cartel_btn_area">
						<div v-if="item_member_info.cartl_member_grade_chg_info.chg_cartl_member_grade_number" class="">
							<div class="flex-row align-center size-px-14 mb-10 cursor-pointer" style="gap: 6px" @click="is_on_grade = true">
								{{ item_member_info.cartl_member_grade_chg_info.chg_cartl_member_grade_name }}
								<img :src="require('@/assets/image/web/home_cartel/ico_question.svg')" />
							</div>
							<div class="flex-row align-center">
								<button v-if="is_join_confirm" @click="onInvite" class="m_invite cursor-pointer">
									<span>멤버초대</span>
								</button>
								<div v-else-if="is_join_wait" class="btn btn-blue radius-20 pa-10-20">
									<span>{{ cartel.cartl_entry_state_name }}</span>
								</div>

								<div v-else-if="is_join_cancel" class="btn btn-blue radius-20 pa-10-20">
									<span>{{ cartel.cartl_entry_state_name }}</span>
								</div>

								<div v-if="is_join" class="">
									<button class="profile_set ml-10 cursor-pointer" @click="toSetting">
										<span>설정</span>
									</button>
								</div>
							</div>
							<div
								v-if="cartel.member_cartl_subscrp_fg == 'Y'"
								class="mt-10"
							>
								<button class="profile_set cursor-pointer" @click="toSetting">
									<span>구독중 | </span>
									<span>{{ cartel.member_cartl_subscrp_plan_info.subscrp_plan_name }}</span>
								</button>
							</div>
						</div>

						<div
							v-else
							class="btn btn-blue radius-20 pa-10-20"
						>
							<span>미가입 카르텔</span>
						</div>
					</div>

					<div class="flex-1 flex-column justify-center text-center cursor-pointer" @click="toHome">
						<h2>{{ cartel.cartl_name }}</h2>
						<div>{{ cartel.cartl_desctn }}</div>
					</div>

					<div class="flex-column justify-end text-right" style="flex-basis: 220px">
						<div class="level font-weight-700">{{ cartel.cartl_level_name }}</div>
						<div class="member flex-row justify-end">
							<div class="badge_30 mr-10" :class="'badge_30_' + cartel.type + '_bl'" style="width: 30px; height: 30px;">
								<em class="hide">{{ cartel.type }}</em>
							</div>
							<div class="flex-column justify-center gap-10">
								<div class="size-px-14 font-weight-400 justify-center align-center" style="gap: 4px">
									<img :src="require('@/assets/image/web/home_cartel/ico_interest_influence.svg')" width="30px" style="width: 30px; margin-right: 8px" /> 멤버수<b
									class="font-weight-700"
								>
									{{ cartel.current_member_count }}</b
								>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="cartel-menu">
			<div class="flex-row" style="width: 1200px; margin: 0 auto">
				<div class="flex-row justify-space-between" style="width: 900px;">
					<div class="mr-20 flex-column justify-center hover-pointer color-bbb" :class="{ on: cartel_position.home }" @click="toHome">홈</div>
					<div v-if="false" class="mr-20 flex-column justify-center hover-pointer" :class="{ on: cartel_position.drops }">NFT DRPOS</div>
					<div class="flex-column justify-center flex-1" style="overflow: hidden; white-space: nowrap">
						<ul class="wrap-cartel-menu flex-row full-height" style="overflow: auto" ref="menu-box">
							<li
								v-for="(menu, m_index) in items_board_config"
								:key="'menu_' + m_index"
								class="mr-20 hover-pointer flex-column justify-center color-bbb"
								:class="{ on: menu.board_number == $route.params.b_id }"
								@click="toList(menu)"
							>
								{{ menu.board_name }}
							</li>
						</ul>
					</div>
					<div style="flex-basis: 50px" class="text-center flex-column justify-center align-center cursor-pointer" @click="toScroll">
						<img :src="require('@/assets/image/web/index_cartel/ico_arrow_right_lg.svg')" style="width: 10px" />
					</div>
				</div>
				<div class="flex-1 ptb-10">
					<div class="flex-row box radius-20" style="margin-left: auto; width: 300px;">
						<input v-model="item_search.search_value" class="pa-10-20 flex-1" placeholder="현재 카르텔 검색" @keyup.enter="toSearch" maxlength="20" />
						<v-icon class="color-dadada" v-if="item_search.search_value" @click="item_search.search_value = ''">mdi-close-circle</v-icon>
						<v-icon class="ml-10 mr-10 cursor-pointer" @click="toSearch">mdi-magnify</v-icon>
					</div>
				</div>
			</div>
		</div>

		<mafia057
			v-if="is_on_invite" :user="user" @cancel="is_on_invite = false"
		></mafia057>

		<PopupLayer v-if="is_on_grade">
			<template v-slot:body>
				<div>
					<div class="ma-auto width-500 radius-20 bg-white pa-40-30 position-relative">
						<div>
							<div class="popup-close">
								<img class="cursor-pointer" @click="is_on_grade = false" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" />
							</div>

							<div class="flex-column justify-center align-center gap-10 under-line pb-30">
								<img :src="require('@/assets/image/web/ico_logo_small.svg')" class="mb-10" />
								<span class="size-px-16 font-weight-500 color-333">{{ cartel.cartl_name }}</span>
								<h4>등급안내</h4>
							</div>
						</div>

						<div class="pt-30 scroll-gray">
							<ul style="max-height: 183px; overflow-y: scroll">
								<li class="justify-space-between size-px-14 font-weight-700 color-mafia-blue under-line pt-20 pb-20" style="padding-right: 15px">
									{{ items_grade[2].cartl_member_grade_name }}
									<span class="size-px-13 font-weight-400 color-333">가입 직후 활동 멤버</span>
								</li>
								<li
									v-for="(grade, g_index) in member_grade_list" :key="'grade_' + g_index"
									class="justify-space-between size-px-14 font-weight-700 color-mafia-blue under-line pt-20 pb-20" style="padding-right:15px"
								>
									{{ grade.cartl_member_grade_name }}
									<div class="size-px-13 font-weight-400 color-333">
										<span class="">NFT 보유수량 {{ grade.nft_holding_quantity }}개</span>,
										<span class="">점수 {{ grade.score }}점</span>
									</div>
								</li>
							</ul>


						</div>
						<p class="size-px-13 font-weight-500 color-888 mt-20">점수는 방문수(1점) + 게시글(3점)의 합산입니다.</p>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>
import Mafia057 from '@/view/Cartel/mafia057';
import PopupLayer from '@/view/Layout/PopupLayer';

export default {
	name: 'CartelTop',
	props: ['user', 'cartel'],
	components: { Mafia057, PopupLayer },
	data: function () {
		return {
			items_board_config: [],
			item_member_info: {
				cartl_member_grade_chg_info: {

				}
			},
			item_slide: {
				d: 550,
				p: 1,
				doing: false,
			}
			, is_on_invite: false
			, item_search: {
				search_value: this.$route.params.sarch_value,
			}
			, item_permission: {}
			, is_on_grade: false
			, items_grade: [
				{ cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0 },
				{ cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0 },
				{ cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0 },
			]
			, item_grade_info: {}
			, bg_cover: ''
		};
	},
	computed: {
		is_join_confirm: function () {
			let t = false;
			switch (this.cartel.cartl_entry_state_code) {
				case 'CA00300004':
					t = true;
					break;
			}
			return t;
		},
		is_join: function () {
			let t = false;
			if (this.cartel.cartl_entry_state_code == 'CA00300004') {
				t = true;
			}
			return t;
		},
		is_join_cancel: function () {
			let t = false;
			switch (this.cartel.cartl_entry_state_code) {
				case 'CA00300003':
				case 'CA00300005':
				case 'CA00300006':
				case 'CA00300007':
					t = true;
					break;
			}
			return t;
		},
		is_join_wait: function () {
			let t = false;
			switch (this.cartel.cartl_entry_state_code) {
				case 'CA00300001':
				case 'CA00300002':
					t = true;
					break;
			}
			return t;
		},
		is_setting: function () {
			let t = false;

			if (this.cartel.cartl_entry_state_code == 'CA00300004') {
				t = true;
			}

			t = true;

			return t;
		},
		cartel_position: function () {
			let t = {};
			if (this.$route.params.b_id) {
				t[this.$route.params.b_id] = true;
			} else if (this.$route.name == 'drops') {
				t.drops = true;
			} else {
				t.home = true;
			}

			return t;
		},
		member_grade_list: function () {
			let t = [];

			this.items_grade.filter((item, index) => {
				if (index > 2) {
					t.push(item);
				}
			});

			return t;
		},
		request_items_grade: function () {
			return this.items_grade.filter((item) => {
				item.nft_holding_quantity = Number(item.nft_holding_quantity);
				item.score = Number(item.score);

				return item;
			});
		}
	},
	methods: {
		toSetting: function () {
			if (this.item_permission.cartl_member_div_code == 'CA02500001') {
				this.$bus.$emit('to', { name: 'mafia074', params: { idx: this.$route.params.idx, from: 'mafia049' } });
			} else {
				this.$bus.$emit('to', { name: 'mafia076', params: { idx: this.$route.params.idx, from: 'mafia049' } });
			}
		}
		, getCartelMenu: async function () {
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_menu
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx

					}
					, type: true,
				});

				if (result.success) {
					this.item_member_info = result.data;
					this.items_board_config = result.data.board_list;

					this.$bus.$emit('setBoardConfigSort', this.items_board_config);
				} else {
					throw result.message;
				}
			} catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e });
				this.$log.console(e);
			} finally {
				this.$bus.$emit('on', false);
			}
		},
		toScroll: function () {
			if (!this.item_slide.doing) {
				this.item_slide.doing = true;

				let t = this.$refs['menu-box'].scrollWidth;
				let tp = Math.ceil(t / this.item_slide.d);

				let x = this.item_slide.d * this.item_slide.p;

				this.$log.console(this.item_slide.d, this.item_slide.p, x);
				let p = 10;
				let pp = setInterval(() => {
					if (this.item_slide.p == 0) {
						this.$log.console('finish', p, x);
						clearInterval(pp);
						this.$refs['menu-box'].scrollTo(0, 0);

						this.item_slide.p++;
						this.item_slide.doing = false;
					} else if (p >= x) {
						this.$log.console('finish', p, x);
						clearInterval(pp);

						this.item_slide.p++;
						if (this.item_slide.p >= tp) {
							this.item_slide.p = 0;
						}
						this.item_slide.doing = false;
					} else {
						this.$refs['menu-box'].scrollTo(p + (x - this.item_slide.d), 0);
						p += 10;
					}
				}, 10);
			}
		}
		, toList: function (item) {
			this.$log.console(item);
			if (item.board_authority.inquiry_fg != 'Y') {
				this.$bus.$emit('notify', { type: 'error', message: '조회권한이 없습니다.' });
			} else {
				if (item.board_type_code === 'CA00700001') {
					this.$bus.$emit('to', {
						name: 'mafia053',
						path: '/cartel/mafia053/' + this.$route.params.idx + '/' + item.board_type_code + '/' + item.board_number,
						params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, from: this.$route.name },
						not_query: true,
					});
				} else {
					this.$bus.$emit('to', {
						name: 'mafia053',
						path: '/cartel/mafia053/' + this.$route.params.idx + '/' + item.board_type_code + '/' + item.board_number,
						params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, from: this.$route.name },
						not_query: true,
					});
				}
			}
		},

		getData: async function () {
			try {
				this.$bus.$emit('on', true);
				const result = await this.$Request({
					method: 'post',
					url: this.$api_url.api_path.get_cartel_member_grade_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true,
				});

				if (result.success) {
					this.items_grade = result.data.cartl_member_grade_list;
					this.item_grade_info = result.data.resultinfo;
				} else {
					throw result.message;
				}
			} catch (e) {
				this.$log.console(e);
				this.$bus.$emit('notify', { type: 'error', message: e });
			} finally {
				this.$bus.$emit('on', false);
			}
		}
		, getCartel: async function () {
			try {
				this.$bus.$emit('on', true);
				const result = await this.$Request({
					method: 'post',
					url: this.$api_url.api_path.get_cartel_info,
					data: {
						member_number: this.user.member_number,
						cartl_number: this.$route.params.idx,
					},
					type: true,
				});

				if (result.success) {
					this.cartel = result.data;
				} else {
					throw result.message;
				}
			} catch (e) {
				this.$log.console(e);
				this.$bus.$emit('notify', { type: 'error', message: e });
			} finally {
				this.$bus.$emit('on', false);
			}
		},
		toHome: function () {
			this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx } });
		},
		onInvite: function () {
			this.is_on_invite = true;
		},
		toSearch: function () {
			if (this.item_search.search_value) {
				if (this.item_search.search_value.length < 2) {
					this.$bus.$emit('notify', { type: 'error', message: '검색어는 두글자 이상 입력하세요' });
				} else {
					this.$bus.$emit('to', { name: 'mafia048', params: { idx: this.$route.params.idx, search_value: this.item_search.search_value } });
				}
			} else {
				this.$bus.$emit('notify', { type: 'error', message: '검색어를 입력하세요' });
			}
		},
		getPermission: async function () {
			try {
				this.$bus.$emit('on', true);
				const result = await this.$Request({
					method: 'post',
					url: this.$api_url.api_path.get_cartel_permission,
					data: {
						member_number: this.user.member_number,
						cartl_number: this.$route.params.idx,
					},
					type: true,
				});
				if (result.success) {
					this.item_permission = result.data;
				} else {
					throw result.message;
				}
			} catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e });
				this.$log.console(e);
			} finally {
				this.$bus.$emit('on', false);
			}
		}
		, mount: async function(){

			// await this.getCartel();
			// this.cartel = this.cartel_info
			await this.getCartelMenu();
			if (this.item_member_info.cartl_member_grade_number) {
				await this.getPermission();
			}
			await this.getData()
		}

		, getBgCover: function(){
			let t = ''

			if(this.cartel.cartl_web_img_url){
				t = 'background-image: url(' + this.cartel.cartl_web_img_url + '); background-repeat: repeat-x; background-position: center;'

				if(this.$refs.bg_cover.height < 300){
					t += 'background-size: auto 100%;'
				}
			}else if(this.cartel.cartl_img_url){
				t = 'background-image: url(' + this.cartel.cartl_img_url + '); background-repeat: repeat-x; background-position: center;'

				if(this.$refs.bg_cover.height < 300){
					t += 'background-size: auto 100%;'
				}
			}

			// console.log('t', t)

			this.bg_cover = t
		}
	},

	async created() {
		// console.log('this.cartel', this.cartel)
		await this.mount()
		this.$log.console('this.item_member_info', this.item_member_info)
		setTimeout( () => {
			this.getBgCover()
		}, 200)
	},
	watch: {
		$route(to, from) {
			this.$log.console(to, from);
			this.mount()

			if (this.cartel_position.home) {
				this.item_search.search_value = this.$route.params.search_value;
			} else {
				this.item_search.search_value = '';
			}
		},
		cartel: {
			handler: function(){
				setTimeout( () => {
					this.getBgCover()
				}, 200)
			}
		}
	},
};
</script>

<style>
.cartel-info {
	height: 300px;
}

.cartel-menu {
	font-size: 16px;
	font-weight: 700;
}

.wrap-cartel-menu::-webkit-scrollbar {
	height: 0px;
	background-color: #333;
}
.wrap-cartel-menu::-webkit-scrollbar-thumb {
	background-color: #2f3542;
}
.wrap-cartel-menu::-webkit-scrollbar-track {
	background-color: grey;
}

input::placeholder{
	color: #bbb;
	font-size: 14px;
	font-weight: 400;
}

.scroll-gray ul::-webkit-scrollbar{width: 4px; border-radius: 2px;}
.scroll-gray ul::-webkit-scrollbar-thumb{width: 4px; border-radius: 2px; background: #bdbdbd;}
.scroll-gray ul::-webkit-scrollbar-track{width: 4px; border-radius: 2px; background: #e9e9e9;}
</style>
