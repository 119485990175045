<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="flex-column radius-20 text-left  radius-20 overflow-hidden"
				style="min-width: 240px; max-width: 480px; margin: 0 auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>

				<div class="bg-mafia-blue flex-row justify-space-between items-center pa-10-20">
					<h4 class="color-white ml-10">가입 카르텔</h4>
					<button
						@click="$emit('cancel')"
					><v-icon class="color-white">mdi-close-circle</v-icon></button>
				</div>

				<div class="bg-white pa-30">
					<div
						class="flex-column items-center"
						style="max-height: 240px"
						ref="post_list_item_view"
					>
						<table class="nft_info_table">
							<colgroup>
								<col width="75%">
								<col width="25%">
							</colgroup>
							<tbody>
							<template
								v-if="items.length > 0"
							>
								<tr
									v-for="(item, index) in items"
									:key="'item_' + index"
									class="cursor-pointer"
								>
									<td
										@click="toCartel(item)"
									>{{ item.cartl_name }}</td>
								</tr>
							</template>
							<tr
								v-else
							>
								<td colspan="3" class="td_list_none">
									<div class="list_none">
										<img :src="require('@/assets/image/list_none.png')" alt="이력없음">
										<span>가입된 카르텔이 없습니다.</span>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia0981'
	,
	components: {PopupLayer},
	props: ['user', 'member']
	, data: function(){
		return {
			program: {
				name: 'STAKING 내역'
			}
			, items: []
			, item_search: this.$storage.init({
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_member_cartel_list
					, data: {
						member_number: this.user.member_number
						, inquiry_member_number: this.member.member_number ? this.member.member_number : this.member.post_member_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.page_number
					}
					, type: true
				})
				if(result.success){
					this.items = result.data.cartl_search_result
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toCartel: function(item){
			this.$bus.$emit('to', { name: 'mafia049', path: '/cartel/mafia049/' + item.cartl_number, params: { idx: item.cartl_number}})
			this.$bus.$emit('offProfile')
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.$log.console('member', this.member)
		this.getData()
	}
}
</script>