<template>
	<div class="">
		<div class="bg-white radius-20 pa-24 ">
			<div class="under-line-bbb pb-16 flex-row">
				<h3 class="flex-1 size-px-20">설정</h3>
			</div>

			<div>
				<ul class="mt-16">
					<template
						v-for="(menu, m_index) in menu_list"
					>
						<li
							v-if="menu.is_permission"
							:key="'menu_' + m_index"
							class="under-line-not-last cursor-pointer " style="padding:14px 0"
							:class="[{on: menu.is_on}, {cartel_setting_menu: is_boss}]"
						>
							<div class="flex-row">
								<img
									v-if="menu.is_on"
									:src="menu.ico_on"
									class="mr-10"
								>
								<img
									v-else
									:src="menu.ico"
									class="mr-10"
								>
								<div
									class=""
									@click="toSetting(menu)"
								>{{  menu.title }}</div>
							</div>
							<div
								v-if="menu.is_sub"
								class="mt-10 size-px-14 bg-gray01 ptb-10 radius-12 subscribe_submenu"
							>
								<div
									v-for="(sub, s_index) in menu.sub"
									:key="'sub_' + s_index"
									class=""
									@click="toSetting(sub)"
								>
									<span>
										{{ sub.title }}
									</span>
								</div>
							</div>
						</li>
					</template>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CartelSetting'
		, props: ['user']
		, data: function(){
			return {
				item_permission: {}
				, icon_on: false
				, items_menu: [
					{
						name: 'member'
						, title: '카르텔 설정'
						, to: { name: 'mafia074', params: {idx: this.$route.params.idx}}
						, permission: 'is_boss'
						, ico: require("@/assets/image/web/setting_cartel/ico_set01_off.svg")
						, ico_on: require("@/assets/image/web/setting_cartel/ico_set01_on.svg")
					}
					, {
						name: 'member', title: '알림'
						, to: { name: 'mafia076', params: {idx: this.$route.params.idx}}
						, permission: true
						, ico: require("@/assets/image/web/setting_cartel/ico_set02_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set02_on.svg")
					}
					, {
						name: 'member', title: '멤버 보기'
						, to: { name: 'mafia082', params: {idx: this.$route.params.idx}}
						, permission: true
						, ico: require("@/assets/image/web/setting_cartel/ico_set03_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set03_on.svg")
					}
					, {
						name: 'member', title: '카르텔 탈퇴'
						, to: { name: 'mafia083', params: {idx: this.$route.params.idx}}
						, permission: 'not_boss'
						, ico: require("@/assets/image/web/setting_cartel/ico_set04_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set04_on.svg")
					}
					, {
						name: 'support', title: '구독 내역'
						, to: {name: 'CartelSubscribeList' , params: { idx: this.$route.params.idx}}
						, permission: true
						, ico: require('@/assets/image/ico_subscribe_off.svg'), ico_on: require('@/assets/image/ico_subscribe.svg')
					}
					, {
						name: 'member', title: '운영자 관리'
						, to: { name: 'mafia084', params: {idx: this.$route.params.idx}}
						, permission: 'is_underboss_manage'
						, ico: require("@/assets/image/web/setting_cartel/ico_set05_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set05_on.svg")
					}
					, {
						name: 'member', title: '멤버등급 관리'
						, to: { name: 'mafia0841', params: {idx: this.$route.params.idx}}
						, permission: 'is_member_grade_manage'
						, ico: require("@/assets/image/web/setting_cartel/ico_set06_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set06_on.svg")
					}
					, {
						name: 'member', title: '게시판 관리'
						, to: { name: 'mafia122', params: {idx: this.$route.params.idx}}
						, permission: 'is_board_manage'
						, ico: require("@/assets/image/web/setting_cartel/ico_set07_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set07_on.svg")
					}
					, {
						name: 'member', title: '구독 관리'
						, to: { name: 'SubscribeManagement', params: {idx: this.$route.params.idx}}
						, permission: 'is_board_manage'
						, ico: require("@/assets/image/web/setting_cartel/icon_subscribe_management_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/icon_subscribe_management_on.svg")
						, is_sub: false, sub: [
							{ name: 'subscribeManagement', title: '구독 플랜 관리', to: { name: 'SubscribePlane', params: {idx: this.$route.params.idx}}}
							// , { name: 'subscribeManagement', title: '구독 정산', to: { name: 'SubscribePlane', params: {idx: this.$route.params.idx}}}
							, { name: 'subscribeManagement', title: '구독자 관리', to: { name: 'SubscribeMember', params: {idx: this.$route.params.idx}}}
						]
					}
					, {
						name: 'support', title: '후원 내역'
						, to: {name: 'CartelSupportHistory' , params: { idx: this.$route.params.idx}}
						, permission: 'is_boss'
						, ico: require('@/assets/image/icon_heart_outline_off.svg'), ico_on: require('@/assets/image/icon_heart_outline.svg')
					}
					, {
						name: 'member', title: '카르텔 통계'
						, to: { name: 'mafia0911', params: {idx: this.$route.params.idx}}
						, permission: 'is_boss'
						, ico: require("@/assets/image/web/setting_cartel/ico_set08_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set08_on.svg")
					}
					, {
						name: 'member', title: '회원 관리'
						, to: { name: 'mafia091', params: {idx: this.$route.params.idx}}
						, permission: 'is_member_manage'
						, ico: require("@/assets/image/web/setting_cartel/ico_set09_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set09_on.svg")
					}
					, {
						name: 'member', title: '카르텔 폐쇄'
						, to: { name: 'mafia0831', params: {idx: this.$route.params.idx}}
						, permission: 'is_boss'
						// , ico: require("@/assets/image/web/setting_cartel/ico_set04_off.svg"), ico_on: require("@/assets/image/web/setting_cartel/ico_set04_on.svg")
					}
				]
			}
		}
		, computed: {

			menu_list: function(){
				return this.items_menu.filter( (item) => {

					item.is_on = false

					switch (item.permission){
						case 'is_boss':
							item.is_permission = this.is_boss
							break;
						case 'not_boss':
							item.is_permission = !this.is_boss
							break;
						case 'is_underboss_manage':
							item.is_permission = this.is_underboss_manage
							break;
						case 'is_board_manage':
							item.is_permission = this.is_board_manage
							break;
						case 'is_member_grade_manage':
							item.is_permission = this.is_member_grade_manage
							break;
						case 'is_member_manage':
							item.is_permission = this.is_member_manage
							break;
						default:
							item.is_permission = true
							break;
					}
					if(item.to.name == this.$route.name){
						item.is_on = true
					}else{
						if(item.sub){
							item.sub.filter( ( sub ) => {
								if(sub.to.name == this.$route.name ){
									item.is_on = true
									item.is_sub = true
								}
							})
						}
					}
					console.log(item.sub)
					return item
				})
			}
			, is_boss: function(){
				let t = false

				if(this.item_permission.cartl_member_div_code == 'CA02500001'){
					t = true
				}
				return t
			}
			, is_underboss_manage: function(){
				let t = false
				if(this.item_permission.cartl_admin_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_board_manage: function(){
				let t = false
				if(this.item_permission.cartl_board_creation_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_member_manage: function(){
				let t = false
				if(this.item_permission.cartl_member_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_member_grade_manage: function(){
				let t = false
				if(this.item_permission.cartl_member_grade_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
		}
		, methods: {
			toSetting: function(item){
				if(item.sub){
					this.$set(item, 'is_sub', !item.is_sub)
				}else{
					this.$bus.$emit('to', item.to)
				}
			}

			, getPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.item_permission = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					this.$log.console(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$log.console('user', this.user)
			this.getPermission()
		}
	}
</script>

<style scoped>
.cartel_setting_menu:last-child{
	font-size: 14px;
	color: var(--gray01);
	margin-left: -10px;
}

.subscribe_submenu{

	>div{
		position: relative;
		padding: 4px 14px;

		>span{
			position: relative;
			padding-left: 11px;
		}

		>span::after{
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 3px;
		height: 3px;
		border-radius: 100px;
		background-color: var(--light-Gray02);
	}
	}


}
</style>