<template>
	<div
		class="inline-block icon color-black mr-10 cursor-pointer"
		:class="icon"

		@click="onProfile"
	>
		<img
			v-if="img_src"
			:src="img_src"
			@error="$event.target.src=require('@/assets/image/web/mypage_cartel/@profile_inner.png')"
			class="object-cover"
		/>
		<img
			v-else
			:src="require('@/assets/image/web/mypage_cartel/@profile_inner.png')"
			class="object-cover"
		/>
	</div>
</template>

<script>

	export default {
		name: 'ProfileImage'
		, props: ['user', 'item_info', 'icon_type', 'cartel_info']
		, data: function(){
			return {
				is_on_profile: false
			}
		}
		, computed: {
			icon: function(){
				let t = 'icon-profile-'
				t += this.icon_type
				return t
			}
			, img_src: function(){
				let t = ''
				if(this.item_info.profle_nft_card_img_url){
					t = this.item_info.profle_nft_card_img_url

				}
				if(this.item_info.post_member_profle_nft_card_img_url){
					t = this.item_info.post_member_profle_nft_card_img_url
				}
				// this.$log.console('t', t)
				return t
			}
		}
		, methods: {
			onProfile: function(){
				this.$set(this.item_info, 'profile_image', this.img_src)
				this.$bus.$emit('onProfile', this.item_info, this.item_info)
			}
		}
		, created() {
			// this.$log.console('profile_image')
		}
	}
</script>

<style>
	.icon-profile-ss { width: 32px; height: 32px}
	.icon-profile-s { width: 40px; height :40px;}
	.icon-profile-m { width: 66px; height: 66px}
	.icon-profile-l { width: 120px; height: 120px;}
	.icon-profile-xl { width: 132px; height: 132px;}
</style>