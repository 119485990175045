<template>
	<CSlide
		:items="items_banner"

		@click="toDetail"
	></CSlide>
</template>

<script>
	import CSlide from "@/components/Slide";
	export default {
		name: 'banner'
		, props: ['user']
		, components: {CSlide}
		, data: function(){
			return {

				items_banner: [
					{
						name: ''
						, url: require('@/assets/image/banner/main_banner03.jpg')
						, path: ''
					}
				]
			}
		}
		, methods:{
			toDetail: function(banner){
				if(banner.path){
					this.$bus.$emit('to', { path: banner.path })
				}
			}
		}
	}
</script>