<template>
	<div class="position-fixed-full z-index-layer">
		<div class="bg-layer"></div>
		<div class="position-relative bg-black viewer pa-20 flex-column justify-center full-width full-height opacity-8">
			<div class="full-height flex-column justify-space-between">
				<div class="text_guide pt-20 full-height flex-column justify-center items-center">

					<div class="text-center">
						<img :src="require('@/assets/image/intro_logoTilte.png')" class="width-100"/>
					</div>
					<div
						v-if="false"
						class="mt-50 text-center"
					>
						<h5
							v-if="type == 'mobile'"
							class="color-white"
						>모바일 환경으로 접속하였습니다.</h5>
						<h5
							v-else
							class="color-white"
						>PC 환경으로 접속하였습니다.</h5>
					</div>
				</div>
				<div
					v-if="false"
					class="ma-auto"
				>
					<button
						class="block width-100 pa-10-20 btn-primary radius-20"
						@click="toMode()"
					>모바일 버전 보기</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {common} from "@/assets/js/common";

export default {
	name: 'mobile'
	, data: function(){
		return {
			program: {
				not_header: true
				, not_footer: true
			}
			, type: ''
		}
	}
	, methods: {
		is_mobile: function(){

			if(common.isMobile() == 'CM00500003'){
				this.type = 'mobile'
			}else{
				this.type = 'web'
			}
		}
		, toMode: function(){

			let location = window.location.href
			let domain = process.env.VUE_APP_DOMAIN
			let domain_m = process.env.VUE_APP_DOMAIN_M
			let domain_stg = process.env.VUE_APP_DOMAIN_STG
			let domain_stg_m = process.env.VUE_APP_DOMAIN_STG_M
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV
			let domain_dev_m = process.env.VUE_APP_DOMAIN_DEV_M
			let domain_local = process.env.VUE_APP_DOMAIN_LOCAL
			let domain_local_m = process.env.VUE_APP_DOMAIN_LOCAL_M

			if(location.indexOf(domain) > -1){
				document.location.href = domain_m
			}else if(location.indexOf(domain_stg) > -1) {
				document.location.href = domain_stg_m
			}else if(location.indexOf(domain_dev) > -1) {
				document.location.href = domain_dev_m
			}else if(location.indexOf(domain_local) > -1) {
				document.location.href = domain_local_m
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.toMode()
	}
}
</script>