

import { language } from '@/resources/lang/kr/common'
import {Axios} from "@/resources/axios/axios";

export const sample = {
	language: language,
	Axios: Axios,

	returnResult: function(type, data, message){

		this.$log.console('returnResult', type, data, message)
		if(data && message) {
			return {success: type, data: data, message: '더미 데이터: ' + message}
		}else if(data && !message){
			return {success: type, data: data}
		}else if(!data && message){
			return {success: type, message: message}
		}else{
			return { success: type, data: data, message: message}
		}
	}
	, updateSampleData: function(type, item){
		let data = localStorage.getItem(type)
		if(data){
			data = JSON.parse(data)
			for(let i of data){
				if(i.id == item.id){
					i = item
					return true
				}
			}
		}

		return false
	}
	,getSampleData: function({type, key, val, search_type, search_value, operator, limit}){

		try{

			let is_do = false
			if(type == 'currency' || type == 'notice' || type == 'faq' || type == 'card_nft_list' || type == 'card_all_list' || type == 'drops_config' || type == 'board_config' || type == 'cartel_stat'|| type == 'member_rank'){
				let items = this[type]
				if(key){
					for(let i = 0; i < items.length; i ++){
						if(items[i][key] == val){
							is_do = true
							return this.returnResult(true, items[i])
						}
					}
					if(!is_do){
						return this.returnResult(false, false, false)
					}
				}else if(search_type && search_value){
					let data = []

					for(let i = 0; i < items.length; i ++){
						if(operator){
							if(operator == '>'){
								if(items[i][search_type] > search_value){
									data.push(items[i])
								}
							}else if(operator == '>='){
								if(items[i][search_type] >= search_value){
									data.push(items[i])
								}
							}else if(operator == '!='){
								this.$log.console(1111111111)
								if(!items[i][search_type] || items[i][search_type] != search_value){
									data.push(items[i])
								}
							}
						}else{
							if(items[i][search_type].indexOf(search_value) > -1){
								data.push(items[i])
							}
						}
					}

					if(limit){
						data = data.slice(0, limit)
					}
					return this.returnResult(true, data)
				}else{
					if(limit){
						items = items.slice(0, limit)
					}
					return this.returnResult(true, items)
				}
			}
			if(!type){
				throw ' 샘플 데이터 타입이 없습니다'
			}
			let data = localStorage.getItem(type)

			if(!data){
				data = []
			}else{
				data = JSON.parse(data)
			}

			this.$log.console(`search_type: ${search_type}, search_value: ${search_value}`)

			if(key){
				let return_item = []
				for(let i = 0; i < data.length; i++){
					let item = data[i]
					if(item[key] == val){
						is_do = true
						return_item.push(item)
					}
				}

				if(return_item.length == 1){
					if(key == 'id' || key == 'is_main'){
						is_do = true
						data = return_item

						return this.returnResult(true, data, false)
					}
				}else{
					data = return_item
				}

				if(!is_do){
					return this.returnResult(false, false, false)
				}

				data = return_item
			}

			this.$log.console('data', data)
			if(type == 'bbs_comment'){
				let list = []
				for(let i of data){
					if(i.id == i.upper_id){
						this.$log.console(i.id)
						let result = localStorage.getItem(type)
						if(result){
							result = JSON.parse(result)
							for(let re of result){

								if(re.id != re.upper_id && re.upper_id == i.id){
									if(!i.reply){
										i.reply = []
									}
									i.reply.unshift(re)
									i.reply.sort(function(a, b){
										if(a.reply_id > b.reply_id){
											return 1
										}
										if(a.reply_id < b.reply_id){
											return -1
										}
										return 0
									})
								}
							}
						}
						list.unshift(i)
					}
				}
				data = list
			}

			if(limit){
				data = data.slice(0, limit)
			}
this.$log.console('data', data)
			if(search_type && search_value){
				let return_data = []

				for(let i = 0; i < data.length; i ++){
					if(operator){
						this.$log.console('operator', operator)
						if(operator == '>'){
							if(data[i][search_type] > search_value){
								return_data.push(data[i])
							}
						}else if(operator == '>='){
							if(data[i][search_type] >= search_value){
								return_data.push(data[i])
							}
						}else if(operator == '!='){
							if(!data[i][search_type] || data[i][search_type] != search_value){
								return_data.push(data[i])
							}
						}else if(operator == 'like'){
							if(data[i][search_type].indexOf(search_value) > -1){
								return_data.push(data[i])
							}
						}else{
							if(data[i][search_type] == search_value){
								return_data.push(data[i])
							}
						}
					}else{
						if(data[i][search_type].indexOf(search_value) > -1){
							return_data.push(data[i])
						}
					}
				}

				if(type == 'bbs'){
					for(let i of return_data){
						let result = this.getSampleData({ type: 'bbs_emoji', key: 'bbs_id', val: i.id})
						i.is_empathy = result.success ? result.data[0].code : result.success
						i.empathy_count = result.success ? result.data.length : 0
						result = this.getSampleData({ type: 'bbs_comment', key: 'bbs_id', val: i.id})
						i.comment_count = result.success ? result.data.length : 0
					}
				}

				return this.returnResult(true, return_data)
			}else{

				if(limit){
					data = data.slice(0, limit)
				}
				return this.returnResult(true, data)
			}
		}catch(e){
			return this.returnResult(false, null, e)
		}
	}
	,getToday: function(){
		let today = new Date()

		return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + ' ' + ('0' + today.getHours()).slice(-2) + ':' + ('0' + today.getMinutes()).slice(-2) + ':' + ('0' + today.getSeconds()).slice(-2)
	}
	,postAutoLogin : function(login_data){
		try{
			const result = this.getSampleData({type: 'account', key: 'email', val: login_data.email})
			if(result.success){

				this.$log.console('sample auto login success !!')
				this.$encodeStorage.setItem('_AT', JSON.stringify(login_data))
				sessionStorage.setItem(process.env.VUE_APP_NAME + '_AT', JSON.stringify(login_data))

				return this.returnResult(true, false, false)
			}else{
				throw result.message
			}
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	,getMyinfo: function(){
		try {
			let login_info = sessionStorage.getItem(process.env.VUE_APP_NAME + '_AT')
			if (!login_info) {
				throw '로그인 정보가 없습니다'
			}else{
				login_info = JSON.parse(login_info)
			}
			let result = this.getSampleData({type: 'account'})
			if (result.success) {
				result.data.forEach(function(item){
					if(item.email == login_info.email){
						return login_info = item
					}
				})

				return this.returnResult(true, login_info, '')
			}else{
				throw '오류'
			}
		}catch (e) {
			return this.returnResult(false, null, e)
		}
	}
	,postWallet: function(currency, amount){
		this.$log.console('sample postWallet start !!')
		try{
			if(!currency || !currency.id){
				this.$log.console('sample postWallet not currency id !!')
				throw 'currency_id가 없습니다'
			}else{
				this.$log.console('sample postWallet currency id !!')
				let result = this.getSampleData({ type: 'wallet'})
				this.$log.console(result)
				if(result.success){
					this.$log.console(result.data)
					result.data.forEach(function(item){
						if(item.currency_id == currency.id){
							throw '이미 생성된 지갑이 있습니다'
						}
					})

					let id = result.data.length + 1
					if(!amount){
						amount = 0
					}
					let address = 'address_' + currency.label + '_' + id
					let wallet = {
						id: id
						, currency_id: currency.id
						, amount: amount
						, address: address
						, img_src: currency.img_src
						, label: currency.label
						, name: currency.name
						, user_id: currency.user_id
						, created_at: this.getToday()
					}

					result.data.push(wallet)
					localStorage.setItem('wallet', JSON.stringify(result.data))

					return this.returnResult(true, null, 'success')
				}else{
					return this.returnResult(result.success, null, result.message)
				}
			}
		}catch(e){
			this.$log.console('catch postWallet e')
			this.$log.console(e)
			return this.returnResult(false, null, e)
		}
	}
	,getPurchase: function(){
		try{

			let nft_cards = this.card_nft_list
			let utility_cards = this.card_utility_list
this.$log.console(utility_cards)
			let result = this.getSampleData('my_card')
			let my_card = []
			if(result.success){
				my_card = result.data
			}

			let nft_size = nft_cards.length
			nft_cards.forEach(function(nft,index){
				my_card.forEach(function(card, index2){
					this.$log.console(index, index2, nft.id, card.nft_id)
					if(nft.id == card.nft_id){
						nft_size--
						this.$log.console(index, index2, nft.id, card.nft_id, '!!')
					}else{
						this.$log.console(index, index2, nft.id, card.nft_id)
					}
				})
			})

			this.$log.console('-------------------')

			let utility_size = utility_cards.length
			utility_cards.forEach(function(utility,index){
				my_card.forEach(function(card, index2){
					this.$log.console(index, index2, utility.id, card.nft_id)
					if(utility.id == card.nft_id){
						utility_size--
						this.$log.console(index, index2, utility.id, card.nft_id, '!!')
					}else{
						this.$log.console(index, index2, utility.id, card.nft_id)
					}
				})
			})

			this.$log.console(utility_cards)

			let data = {
				nft: nft_size
				, utility: utility_size
			}

			return this.returnResult(true, data, false)

		}catch (e) {
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	,postBalance: function(currency_id, amount){

		if(!currency_id){
			throw 'currency_id가 없습니다'
		}else{
			let balance = localStorage.getItem('balance')
			if(!balance){
				balance = []
			}else{
				balance = JSON.parse(balance)
			}

			let id = balance.length + 1
			if(!amount){
				amount = 0
			}
			let data = {
				id: id
				, currency_id: currency_id
				, amount: amount
				, created_at: this.getToday()
			}

			balance.push(data)

			localStorage.setItem('balance', JSON.stringify(balance))

			return { success: true, data: {id: id}}
		}
	}
	,updateBalance: function(id, amount){
		try{
			if(!id){
				return false
			}else{
				let wallet = localStorage.getItem('wallet')
				if(!wallet){
					throw '지갑이 없습니다'
				}else{
					wallet = JSON.parse(wallet)
				}

				let self = this
				wallet.forEach(function(item){
					if(item.id == id){
						item.amount = (Number(item.amount) + Number(amount)).toFixed(2)
						item.updated_at = self.getToday()
						return
					}
				})

				localStorage.setItem('wallet', JSON.stringify(wallet))

				return this.returnResult(true, false, false)
			}
		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	,postWithdrawal: function(item){
		try{
			let currency_id = item.currency_id
			let currency = null
			if(!currency_id){
				throw '커런시 정보가 없습니다'
			}else{
				currency = this.getSampleData({ type: 'currency', key: 'currency_id', val: currency_id})
				if(!currency){
					throw '커런시 정보가 없습니다'
				}
			}
			let history = []
			let result = this.getSampleData({type: 'history'})
			this.$log.console(result)
			if(result.success){
				history = result.data
			}else{
				return result
			}

			if(!history){
				history = []
			}
			let id = history.length + 1
			let amount = item.amount > 0 ? item.amount * -1 : item.amount
			let data = {
				id: id
				, currency_id: currency_id
				, address: item.address
				, txid: 'dummy_txid_' + id
				, amount: amount
				, status: 0
				, created_at: this.getToday()
			}

			history.unshift(data)

			localStorage.setItem('history', JSON.stringify(history))

			let wallet = this.getSampleData({ type: 'wallet'})
			this.$log.console('balance: ')
			this.$log.console(wallet)
			if(wallet.success){
				if(wallet.data.length > 0){
					let self = this
					wallet.data.forEach(function(item){
						if(item.currency_id == currency_id){
							this.$log.console(33333)
							self.updateBalance(item.id, amount)
							return
						}
					})
				}else{
					this.$log.console(11111)
					this.postWallet(currency_id, amount)
				}
			}else{
				this.$log.console(22222)
				this.postWallet(currency_id, amount)
			}

			return this.returnResult(true, null, 'success')
		}catch (e) {
			return this.returnResult(false, null, e)
		}
	}
	,postDeposit: function(currency_id){
		try{
			let currency = null
			if(!currency_id){
				throw '커런시 정보가 없습니다'
			}else{
				currency = this.getSampleData({type: 'currency', key: 'currency_id', val: currency_id})
				if(!currency){
					throw '커런시 정보가 없습니다'
				}
			}
			let history = []
			let result = this.getSampleData({ type: 'history'})
			this.$log.console(result)
			if(result.success){
				history = result.data
			}else{
				return result
			}

			if(!history){
				history = []
			}
			let id = history.length + 1
			let amount = (1 + (id / 10)).toFixed(2)
			let data = {
				id: id
				, currency_id: currency_id
				, address: 'dummy_address_' + id
				, txid: 'dummy_txid_' + id
				, amount: amount
				, status: 0
				, created_at: this.getToday()
			}

			history.unshift(data)

			localStorage.setItem('history', JSON.stringify(history))

			let wallet = this.getSampleData({type: 'wallet'})
			this.$log.console('balance: ')
			this.$log.console(wallet)
			if(wallet.success){
				if(wallet.data.length > 0){
					let self = this
					wallet.data.forEach(function(item){
						if(item.currency_id == currency_id){
							this.$log.console(33333)
							self.updateBalance(item.id, amount)
							return
						}
					})
				}else{
					this.$log.console(11111)
					this.postWallet(currency_id, amount)
				}
			}else{
				this.$log.console(22222)
				this.postWallet(currency_id, amount)
			}

			return this.returnResult(true, null, 'success')
		}catch(e){
			return this.returnResult(false, null, e)
		}
	}

	, postKakaoAuthToken: async function(code, kakao_return_url){
		try{
			const result = await this.Axios({
				method: 'post'
				, url: this.language.api_path.post_kakao_auth_token
				, data: {
					code: code
					, grant_type: 'authorization_code'
					, client_id: process.env.VUE_APP_KEY_KAKAO_REST
					, redirect_uri: kakao_return_url
				}
				, isResult: true
			})

			if(result.success){
				return this.returnResult(true, result.data, false)
			}else{
				throw result.data
			}
		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	, getKakaoUserInfo: async function(token_info){
		this.$log.console('getKakaoUserInfo token_info', token_info.access_token)

		try{
			let self = this
			let data = null

			window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			this.$log.console('window.kakao', window.Kakao.Auth.getAccessToken())
			window.Kakao.Auth.setAccessToken(token_info.access_token)
			await window.Kakao.API.request({
				url: '/v2/user/me',
				data: {
					property_keys: ["kakao_account.email"]
				},
				success: function(response) {
					this.$log.console('response', response)
					data = response.kakao_account
				},
				fail: function(error) {
					this.$log.console('error')
					for(let e in error){
						this.$log.console(e, error[e])
					}
					return self.returnResult(false, error, error.msg)
				}
			});
			return self.returnResult(true, data, false)
		}catch (e) {
			this.$log.console('getKakaoUserInfo error', e)
			return this.returnResult(false, false, e)
		}
	}
	,postJoinWithKakao: async function(code, return_url){
		try{
			let token_info = await this.postKakaoAuthToken(code, return_url)
			this.$log.console('token_info', token_info)
			if(token_info.success) {
				let user_info = await this.getKakaoUserInfo(token_info.data)
				this.$log.console('user_info', user_info)
				if (user_info.success) {
					let result = this.getSampleData({type: 'account'})
					if (result.success) {
						for (let i = 0; i < result.data.length; i++) {
							if (result.data[i].email == user_info.data.email) {
								return this.returnResult(false, { name: 'mafia009', params: {type: 'already'}}, '이미 가입된 계정입니다')
							}
						}
					}
					let join_info = localStorage.getItem('join_info')
					if (!join_info) {
						return this.returnResult(false, 'mafia004', '약관 동의내역이 없습니다')
					} else {
						join_info = JSON.parse(join_info)
						let kakao_info = user_info.data
						kakao_info.terms_version = join_info.terms_version
						kakao_info.personal_version = join_info.personal_version
						localStorage.setItem('join_info', JSON.stringify(kakao_info))
						return this.returnResult(true, false, false)
					}
				}else{
					throw user_info.message
				}
			}else{
				throw token_info.message
			}
		}catch (e) {
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	,postJoinWithGoogle: async function(access_token, email){
		try{
			let user_info = {}

			let result = this.getSampleData({type: 'account'})
			if (result.success) {
				for (let i = 0; i < result.data.length; i++) {
					if (result.data[i].email == user_info.data.email) {
						return this.returnResult(false, { name: 'mafia009', params: {type: 'already'}}, '이미 가입된 계정입니다')
					}
				}
			}
			let join_info = localStorage.getItem('join_info')
			if (!join_info) {
				return this.returnResult(false, 'mafia004', '약관 동의내역이 없습니다')
			} else {
				join_info = JSON.parse(join_info)
				let id = this.getSeqId('account')
				let data = {
					id: id
					, email: email
					, nick: join_info.nick
					, memo: join_info.memo
					, fav: join_info.fav
					, terms_version: join_info.terms_version
					, personal_version: join_info.personal_version
				}

				localStorage.setItem('join_info', JSON.stringify(data))
				return this.returnResult(true, data, false)
			}
		}catch (e) {
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postSocialLogin: async function(login_info){
		try{
			let result = this.getSampleData({type: 'account'})
			if(!result.success){
				throw '등록된 계정이 아닙니다'
			}

			for(let key in result.data){
				if(result.data[key].email == login_info.email){
					this.$log.console(' match !! ')
					return this.returnResult(true, result.data[key], '')
				}
			}

			throw 'not match'
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	,postLoginWithKakao: async function(code, return_url){

		try{
			let token_info = await this.postKakaoAuthToken(code, return_url)
			this.$log.console('token_info', token_info)
			if(token_info.success){
				let user_info = await this.getKakaoUserInfo(token_info.data)
				this.$log.console('user_info', user_info)
				if(user_info.success){

					let kakao_info = user_info.data
					this.$log.console(' do postLoginWithKakao !!')
					this.$log.console(kakao_info.email)

					let result = this.getSampleData({type: 'account'})
					if(!result){
						throw '등록된 카카오 계정이 아닙니다'
					}

					for(let key in result.data){
						if(result.data[key].email == kakao_info.email){
							this.$log.console(' match !! ')
							return this.returnResult(true, result.data[key], '')
						}
					}

					throw '등록된 카카오 계정이 아닙니다'
				}else{
					throw user_info.message
				}
			}else{
				throw token_info.message
			}
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, null, e)
		}finally {
			this.$log.console('finish postLoginWithKakao !!')
		}

	}
	,postJoin: function(join_info){
		try {

			let result = localStorage.getItem('account')

			let account = []
			if (result) {
				account = JSON.parse(result)
			}

			account.forEach(function(item){
				if(item.email == join_info.email){
					throw '이미 가입된 메일정보입니다'
				}
			})

			let id = account.length + 1
			let data = join_info
			data.id = id
			account.unshift(data)

			this.$log.console('success')

			return this.returnResult(true, account)

		}catch (e){
			this.$log.console('false')
			return this.returnResult(false, null, e)
		}
	}
	,updateNft: async function(item){
		this.$log.console('updateNft item', item)
		try{
			let result = localStorage.getItem('my_card')

			let items = []
			if(result){
				items = JSON.parse(result)
			}

			let is_use = false

			for(let i = 0; i < items.length; i++){
				if(item.id == items[i].nft_id){
					is_use = true
					break
				}
			}
			let data = {}
			if(!is_use){
				let id = items.length + 1

				data.id = id
				data.nft_id = item.id
				data.created_at = new Date()
				data.img_src = item.img_src
				data.price = item.price
				data.summary = item.summary
				data.type = item.type
				data.card = item.card
				data.name = item.name
				data.contract = item.contract
				data.user_id = item.user_id
				items.unshift(data)
				this.$log.console('updateNft data ', data)
			}else{
				data = item
			}
			localStorage.setItem('my_card', JSON.stringify(items))

			return this.returnResult(true, data, null)
		}catch (e){

			this.$log.console('false')
			return this.returnResult(false, null, e)
		}
	}
	,updateUtility: function(item){
		try{
			let result = this.getSampleData({ type: 'my_card'})

			let items = []
			if(result.success){
				items = result.data
			}

			let size = 0
			result = this.getSampleData({type: 'my_card', key: 'is_use', val: true})
			if(result.success){
				size = result.data.length
			}

			if(!item.is_use && size >= 3){
				throw '유틸리티카드는 3개까지 장착할 수 있습니다'
			}else{
				for(let i = 0; i < items.length; i++){
					if(item.id == items[i].id){
						this.$log.console(item)
						items[i].is_use = !item.is_use
					}
				}
			}

			localStorage.setItem('my_card', JSON.stringify(items))

			return this.returnResult(true, null, null)
		}catch (e){

			this.$log.console('false')
			return this.returnResult(false, null, e)
		}
	}
	,updateProfile: function(item){
		try {
			let result = this.getMyinfo()
			if (result.success) {
				//let my_info = result.data
				result = this.getSampleData({ type: 'account'})
				if (result.success) {
					let account = result.data
					for(let i = 0; i < account.length; i++){
						if(account[i].id == item.id){
							account[i] = item
						}
					}

					localStorage.setItem('account', JSON.stringify(account))
				}

				return this.returnResult(true, false, false)
			}else{
				throw '오류'
			}
		}catch (e) {
			return this.returnResult(false, false, false)	
		}
	}
	,postOut: function(){
		try{
			let account = null
			let login_info = null
			let result = this.getSampleData({type: 'account'})

			if(result.success){

				account = result.data
				result = this.getSampleData({type: 'login_info'})

				if(result.success){
					login_info = result.data
					for(let i = 0; i < account.length; i++){

						if(account[i].email == login_info.email){
							account[i].is_out = true
							account[i].out_date = this.getToday()
							localStorage.setItem('account', JSON.stringify(account))
							return this.returnResult(true, false, false)
						}
					}
				}
			}

			return this.returnResult(false, false, '계정 정보가 없습니다')
		}catch (e){
			return this.returnResult(false, false, e)
		}
	}
	, postPresent: function(nft_card, account){
		this.$log.console(nft_card)
		try{
			let result = this.getSampleData({ type: 'card_all_list'})
			let is_card = false
			let is_account = false
			if(result.success){
				for(let i = 0; i < result.data.length; i++){
					if(result.data[i].id == nft_card.id){
						is_card = true
						break
					}
				}
			}
			result = this.getSampleData({ type: 'account'})
			if(result.success){
				for(let i = 0; i < result.data.length; i++){
					if(result.data[i].id == account.address){
						account = result.data[i]
						is_account = true
						break
					}
				}
			}
			if(!is_card){
				throw '존재하지 않는 카드 정보입니다.'
			}
			if(!is_account){
				throw '존재하지 않는 친구 정보입니다.'
			}

			let history = []
			result = this.getSampleData({ type: 'history_nft'})

			if(result.success){
				history = result.data
			}else{
				throw result.message
			}

			if(!history){
				history = []
			}
			let id = history.length + 1
			let amount = id
			let data = {
				id: id
				, nft_id: nft_card.id
				, address: account.address
				, txid: 'dummy_txid_present_' + id
				, amount: amount
				, status: 0
				, created_at: this.getToday()
			}

			history.unshift(data)

			localStorage.setItem('history_nft', JSON.stringify(history))

			return this.returnResult(true, data, false)

		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	, postStaking: function(item){
		try{
			let result = this.getSampleData({type: 'my_card', key: 'card', val:'nft'})
			if(!result.success){
				throw '보유중인 카드가 없습니다'
			}

			for(let i = 0; i < result.data.length; i++){
				if(item.id == result.data[i].id){
					result.data[i].is_staking = !item.is_staking
				}
			}

			this.$log.console(result.data)

			localStorage.setItem('my_card', JSON.stringify(result.data))

			return this.returnResult(true, false, false)
		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	, postDrop: async function({type, wallet}){

		try{
			let drops = this.drops_config[type][wallet.label]
			let cards = []
			if(type == 'nft'){
				cards = this.card_nft_list
				this.$log.console('sample postDrop cards', cards)
			}else if(type == 'utility'){
				cards = this.card_utility_list
			}else{
				throw '존재하지않는 카드입니다.'
			}
			let total = drops.price + drops.fee
			if(total > wallet.amount){
				throw '잔고가 부족합니다.'
			}

			let result = this.getSampleData({type: 'my_card'})
			let my_card = []
			if(result.success){
				my_card = result.data
			}

			this.$log.console('sample postDrop cards', cards)
			for(let i = 0; i < cards.length; i++){
				for(let j = 0; j < my_card.length; j++){
					if(cards[i].id == my_card[j].nft_id){
						cards.splice(i, 1)
						break
					}
				}
			}

			if(cards.length <= 0){
				throw '구매 가능한 카드가 없습니다.'
			}

			let num = Math.floor(Math.random() * cards.length)
			let card = cards[num]
this.$log.console('sample postDrop card', num, card)
			await this.cardDrop(type, card)

			wallet.amount = total
			result = await this.postWithdrawal(wallet)
			if(!result.success){
				throw result.message
			}

			result = await this.postDepositNft(card.id, wallet)

			card.user_id = wallet.user_id
			result = await this.updateNft(card)

			if(!result.success){
				throw result.message
			}

			if(result.success){
				return this.returnResult(true, result.data, false)
			}else{
				throw result.message
			}
		}catch (e) {
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postDepositNft: function(card_id, wallet){
		try{
			let card = null
			if(!card_id){
				throw '카드 정보가 없습니다'
			}else{
				card = this.getSampleData({ type: 'card_all_list', key: 'id', val: card_id})
				if(!card){
					throw '카드 정보가 없습니다'
				}
			}
			let history = []
			let result = this.getSampleData({type: 'history_nft'})

			if(result.success){
				history = result.data
			}else{
				throw result.message
			}

			if(!history){
				history = []
			}
			let id = history.length + 1
			let amount = id
			let data = {
				id: id
				, nft_id: card_id
				, user_id: wallet.user_id
				, address: wallet.address
				, txid: 'dummy_txid_' + id
				, amount: amount
				, status: 0
				, created_at: this.getToday()
			}

			history.unshift(data)

			localStorage.setItem('history_nft', JSON.stringify(history))

			return this.returnResult(true, data, 'success')
		}catch(e){
			return this.returnResult(false, null, e)
		}
	}
	, cardDrop: function(type, card){
		try {
			let result = this.getSampleData({ type: 'my_card'})
			if (type == 'nft') {
				result = this.card_nft_list
			} else {
				result = this.card_utility_list
			}
			if (result.success) {
				let items = result.data
				for (let i = 0; i < items.length; i++) {
					if (items[i].id == card.id) {
						items[i].is_issue = true
					}
				}
			}

			return this.returnResult(true, false, false)
		}catch (e){
			return this.returnResult(false, false, e)
		}
	}
	, updateMainNft: function(item){
		try{
this.$log.console('updateMainNft', item)
			let result = this.getSampleData({type: 'my_card'})
			if(result.success){
				for(let i = 0; i < result.data.length; i++){

					if(item.id == result.data[i].id){
						result.data[i].is_main = item.is_main
						this.$log.console(result.data[i].is_main,  item.is_main)
					}else{
						result.data[i].is_main = false
					}
				}

				localStorage.setItem('my_card', JSON.stringify(result.data))

				result = this.getSampleData({ type: 'account'})
				if(result.success){
					for(let user of result.data){
						if(user.id == item.user_id){
							user.img_src = item.img_src

							localStorage.setItem('account', JSON.stringify(result.data))
							return this.returnResult(true, false, false)
						}
					}
				}
				throw 'not match'
			}

			throw 'no card data'
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}

	,postCartel: function(input_data){
		try {
			let cartel = []
			let result = this.getSampleData({type: 'cartel'})
			if(result.success){
				cartel = result.data
			}

			let id = cartel.length + 1

			input_data.id = id
			input_data.level = 1
			input_data.count = 0
			cartel.unshift(input_data)
			localStorage.setItem('cartel', JSON.stringify(cartel))

			return this.returnResult(true, input_data, false)

		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	, postCartelJoin: async function(item_cartel){
		this.$log.console('sample postCartelJoin', item_cartel)
		try {
			let cartel = []
			let result = this.getSampleData({type: 'my_cartel'})
			if(result.success){
				cartel = result.data
			}

			for(let item of cartel){
				this.$log.console('item of cartel', item, item_cartel)
				if(item.cartel_id == item_cartel.cartel_id){
					throw '이미 가입된 카르텔 입니다'
				}
			}

			let id = cartel.length + 1
			let input_data = item_cartel
			input_data.cartel_id = item_cartel.id
			input_data.id = id

			this.$log.console(input_data.id + ' : ' + item_cartel.id)

			this.$log.console('join', input_data)
			cartel.unshift(input_data)
			localStorage.setItem('my_cartel', JSON.stringify(cartel))

			let list = []
			result = this.getSampleData({type: 'cartel_member'})
			if(result.success){
				list = result.data
			}

			for(let item of list){
				this.$log.console('item of cartel', item, item_cartel)
				if(item.cartel_id == item_cartel.cartel_id && item.user_id == item_cartel.user_id){
					throw '이미 가입된 카르텔 입니다'
				}
			}

			id = this.getSeqId('my_cartel')
			let data = {
				id: id
				, cartel_id: item_cartel.cartel_id
				, cartel_name: item_cartel.title
				, cartel_img_src: item_cartel.img_src
				, user_id: item_cartel.user.id
				, user_nick: item_cartel.user.nick
				, user_img_src: item_cartel.user.img_src
				, user_level: item_cartel.user.level
				, status: item_cartel.is_confirm ? 0 : 1
				, created_at: this.getToday()
			}

			list.unshift(data)
			localStorage.setItem('cartel_member', JSON.stringify(list))

			return this.returnResult(true, false, false)

		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	,postInvite: function({cartel_id, member_id}){
		try{
			let result = this.getSampleData({type: 'cartel'})
			if(result.success){
				for(let cartel of result.data){
					if(cartel.id == cartel_id){
						let user = []
						if(cartel.user){
							user = cartel.user
						}

						if(user.indexOf(member_id) === -1){
							user.push(member_id)
							localStorage.setItem('cartel', JSON.stringify(result.data))
							return this.returnResult(true, false ,false)
						}
					}
				}
			}
			throw '이미 초대된 친구입니다'
		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	, postFav: function(input_data){

		try{
			let result = this.getSampleData({ type: 'board_config_list'})
			if(result.success){
				for(let item of result.data){
					if(input_data.bbs_id == item.id){
						item.fav = !item.fav
						localStorage.setItem('board_config_list', JSON.stringify(result.data))
						return this.returnResult(true, true, true)
					}
				}
			}

			throw 'not match'
		}catch (e) {
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postBbs: function(data){
		try {

			if(data.code == 'notice' && (!data.title || data.title == '')){
				throw '제목을 입력하세요'
			}
			if(!data.content || data.content == ''){
				throw '내용을 입력하세요'
			}
			if(!data.code || data.code == ''){
				throw '게시판 타입이 없습니다'
			}

			let list = []
			let result = this.getSampleData({type: 'bbs'})
			if(result.success){
				list = result.data
			}

			let id = null
			let input_data = {}
			if(data.id){
				for(let item of list){
					if(item.id == data.id){
						item.code = data.code
						item.title = data.title
						item.content = data.content
						item.user_id = data.user_id
						item.nick = data.nick
						item.files = data.files
						item.vote = data.vote
						item.updated_at = this.getToday()

						localStorage.setItem('bbs', JSON.stringify(list))
						return this.returnResult(true, false ,false)
					}
				}
				throw 'not match'
			}else{
				id = list.length + 1
				input_data = {
					id: id
					, code: data.code
					, title: data.title
					, content: data.content
					, user_id: data.user_id
					, nick: data.nick
					, files: data.files
					, vote: data.vote
					, created_at: this.getToday()
				}

				list.unshift(input_data)
				localStorage.setItem('bbs', JSON.stringify(list))
			}

			return this.returnResult(true, false ,false)

		}catch (e) {
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postCartelActivity: function({ user_id, is_active }){
		try{

			let result = this.getSampleData({ type: 'account'})

			if(result.success){

				for(let item of result.data){
					this.$log.console(item)
					if(item.id == user_id){
						item.is_active = is_active
						localStorage.setItem('account', JSON.stringify(result.data))
						return this.returnResult(true, false, false)
					}
				}
			}

			throw this.language.common.error
		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	, postCartelOut: function({ cartel_id, user_id }){
		try{
			let result = this.getSampleData({ type: 'cartel'})
			if(result.success){
				for(let item of result.data){
					if(item.id == cartel_id){
						this.$log.console('item.user', item.user)
						for(let [i, user] of item.user.entries()){
							if(user == user_id){
								item.user.splice(i, 1)
								localStorage.setItem('cartel', JSON.stringify(result.data))
								break
							}
						}
					}
				}
			}

			result = this.getSampleData({ type: 'my_cartel'})
			if(result.success) {
				for (let [i, item] of result.data.entries()) {
					if (item.cartel_id == cartel_id) {
						result.data.splice(i, 1)
						localStorage.setItem('my_cartel', JSON.stringify(result.data))
						return this.returnResult(true, false, false)
					}
				}
			}

			throw this.language.common.error
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}

	, postCartelClosure: function({ cartel_id, user_id }){
		try{

			let result = this.getSampleData({ type: 'my_cartel'})
			if(result.success) {
				for (let [i, item] of result.data.entries()) {
					if (item.cartel_id == cartel_id) {
						result.data.splice(i, 1)
						localStorage.setItem('my_cartel', JSON.stringify(result.data))
					}
				}
			}

			result = this.getSampleData({ type: 'cartel'})
			if(result.success){
				for(let item of result.data){
					if(item.id == cartel_id){
						if(item.user_id != user_id){
							throw '카르텔 폐쇄는 BOSS만 가능합니다' + user_id
						}

						item.is_closure = true
						item.closured_at = this.getToday()
						localStorage.setItem('cartel', JSON.stringify(result.data))
						return this.returnResult(true, false, false)
					}
				}
			}

			throw this.language.common.error
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postCartelPush: async function({ cartel_id, type }){
		try{
			let result = this.getSampleData({ type: 'my_cartel'})
			if(result.success){
				for(let item of result.data){
					if(item.cartel_id == cartel_id){
						item[type] = !item[type]
						localStorage.setItem('my_cartel', JSON.stringify(result.data))
						return this.returnResult(true, false, false)
					}
				}
			}
			throw this.language.common.error
		}catch(e){

			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postCartelAppoint: function( { cartel_id, user }){
		try{
			let list = []
			let result = this.getSampleData({ type: 'under_boss'})
			if(result){
				for(let item of result.data){
					if(item.cartel_id == cartel_id && item.user_id == user.id){
						throw '이미 임명된 멤버입니다'
					}
				}
				list = result.data
			}

			let id = list.length + 1
			let data = {
				id: id
				, cartel_id: cartel_id
				, user_id: user.id
				, user_nick: user.nick
				, user_img_src: user.img_src
				, created_at: this.getToday()
			}

			list.unshift(data)
			localStorage.setItem('under_boss', JSON.stringify(list))

			return this.returnResult(true, false, false)
		}catch(e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postCartelFire: function({user}){
		try{
			this.$log.console('user', user)

			let result = this.getSampleData({ type: 'under_boss'})
			if(result){
				for(let [i, item] of result.data.entries()){
					if(item.id == user.id){
						result.data.splice(i, 1)
						localStorage.setItem('under_boss', JSON.stringify(result.data))
						return this.returnResult(true, false, false)
					}
				}
			}

			throw '없음'
		}catch(e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postCartelPermission: function({ user}){
		try{
			this.$log.console('user', user)

			let result = this.getSampleData({ type: 'under_boss'})
			if(result.success){
				for(let [i, item] of result.data.entries()){
					if(item.id == user.id){
						item = user
						result.data[i] = item
						localStorage.setItem('under_boss', JSON.stringify(result.data))
						return this.returnResult(true, false, false)
					}
				}
			}

			throw '없음'
		}catch(e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postBoardConfig: function(board_config){
		try{

			let list = []
			let result = this.getSampleData({ type: 'board_config_list'})
			if(result.success) {
				list = result.data
			}

			let id;
			if(board_config.id){
				for(let item of result.data){
					if(item.id == board_config.id){
						item.name = board_config.name
						item.is_open = board_config.is_open
						item.permission = board_config.permission
						item.updated_at = this.getToday()
						localStorage.setItem('board_config_list', JSON.stringify(result.data))
						return this.returnResult(true, false, false)
					}
				}
			}else{

				id = list.length + 1
				let data = {
					id: id
					, code: id
					, cartel_id: board_config.cartel_id
					, user_id: board_config.user.id
					, name: board_config.name
					, is_open: board_config.is_open
					, permission: board_config.permission
					, created_at: this.getToday()
				}

				list.unshift(data)

				localStorage.setItem('board_config_list', JSON.stringify(list))

				return this.returnResult(true, false, false)
			}

			throw 'error'
		}catch(e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	,postCartelMemberStatus: async function(update_data){
		try{
			let result = this.getSampleData({type: 'cartel_member'})
			if(result.success){
				for(let item of result.data){
					if(item.cartel_id == update_data.cartel_id && item.user_id == update_data.user_id){
						item.status = update_data.status
						if(item.status == 2){
							item.deactivated_days = update_data.deactivated_days
							item.deactivated_at = this.getToday()
						}
						localStorage.setItem('cartel_member', JSON.stringify(result.data))
						return this.returnResult(true, false ,false)
					}
				}
			}

			throw 'error'
		}catch (e) {
			return this.returnResult(false, false, e)
		}
	}
	, deleteItem: function(type, id){
		try{
			let result = this.getSampleData({type: type})
			if(result.success){
				let list = result.data
				for(let [i, item] of list.entries()){
					if(item.id == id){
						list.splice(i, 1)
						localStorage.setItem(type, JSON.stringify(list))
						return this.returnResult(true, false, false)
					}
				}
			}

			throw this.language.common.error
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, deleteFriend: function(input_data){
		this.$log.console('deleteFriend', input_data)
		try{
			let result = this.getSampleData({type: 'my_friends'})
			if(result.success){
				let list = result.data
				for(let [i, item] of list.entries()){
					if(item.user_id == input_data.user_id && item.friend_id == input_data.friend_id){
						list.splice(i, 1)
						localStorage.setItem('my_friends', JSON.stringify(list))
						return this.returnResult(true, false, false)
					}
				}
			}

			throw this.language.common.error
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postFriend: function(input_data){
		try{
			if(!input_data.type){
				return this.deleteFriend(input_data)
			}
			let list = []
			let result = this.getSampleData({type: 'my_friends'})
			if(result.success){
				list = result.data
			}

			for(let item of list){
				this.$log.console(item.user_id)
				if(item.user_id == input_data.user_id && item.friend_id == input_data.friend_id){
					throw this.language.common.already_friend
				}
			}

			let id = list.length + 1
			let data = {
				id: id
				, user_id: input_data.user_id
				, friend_id: input_data.friend_id
				, nick: input_data.nick
				, memo: input_data.memo
				, is_notify: input_data.is_notify
				, created_at: this.getToday()
			}

			list.unshift(data)
			localStorage.setItem('my_friends', JSON.stringify(list))

			return this.returnResult(true, true, true)
		}catch (e){
			this.$log.console(e)
			return this.returnResult(false, false, e)
		}
	}
	, postAlarm: function({ user_id, friend_id}){
		try{
			let result = this.getSampleData({type: 'my_friends'})
			this.$log.console('result', result)
			if(result.success){
				for(let item of result.data){
					this.$log.console('item', item)
					if(item.user_id == user_id && item.friend_id == friend_id){
						item.is_alarm = !item.is_alarm
						localStorage.setItem('my_friends', JSON.stringify(result.data))
						return this.returnResult(true, false ,false)
					}
				}

				throw 'not match'
			}else{
				throw 'no data'
			}
		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	, deleteBbs: function(item_bbs){
		try{
			let result = this.getSampleData({type: 'bbs'})
			this.$log.console('result', result)
			if(result.success){
				for(let [i, item] of result.data.entries()){
					this.$log.console('item', item)
					if(item.id == item_bbs.id && item.user_id == item_bbs.user_id){
						result.data.splice(i, 1)
						localStorage.setItem('bbs', JSON.stringify(result.data))
						return this.returnResult(true, false ,false)
					}
				}

				throw 'not match'
			}else{
				throw 'no data'
			}
		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	, postComment: function(input_data){

		let sample_type = 'bbs_comment'
		try{
			let list = []
			let result = localStorage.getItem(sample_type)

			if(result) {
				list = JSON.parse(result)
				if(input_data.id){
					for(let item of list){
						if(item.id == input_data.id){

							item.content = input_data.content
							item.img_src = input_data.img_src
							item.updated_at = this.getToday()
							this.$log.console('list', item.content, input_data.content)
							localStorage.setItem(sample_type, JSON.stringify(list))
							return this.returnResult(true, item, false)
						}
					}
				}
			}
			let id = this.getSeqId(sample_type)
			let data = {
				id: id
				, cartel_id: input_data.cartel_id
				, bbs_code: input_data.bbs_code
				, bbs_id: input_data.bbs_id
				, user_id: input_data.user_id
				, user_nick: input_data.user_nick
				, user_img_src: input_data.user_img_src
				, upper_id: input_data.upper_id ? input_data.upper_id : id
				, reply_id: input_data.reply_id ? input_data.reply_id : id
				, upper_nick: input_data.upper_nick
				, content: input_data.content
				, img_src: input_data.img_src
				, created_at: this.getToday()
			}

			list.unshift(data)

			localStorage.setItem(sample_type, JSON.stringify(list))

			return this.returnResult(true, data, false)

		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	, getSeqId: function(type){
		let list = []
		let id = 1
		let result = this.getSampleData({ type: 'seq_id'})
		if(result.success){
			list = result.data
			let is_do = false
			for(let i of result.data){
				if(i.code == type){
					i.seq++
					id = i.seq
					localStorage.setItem('seq_id', JSON.stringify(result.data))
					is_do = true
					break
				}
			}
			if(!is_do){
				let data = {
					code: type
					, seq: id
				}
				list.push(data)
				localStorage.setItem('seq_id', JSON.stringify(list))
			}
		}else{
			let data = {
				code: type
				, seq: id
			}
			list.push(data)
			localStorage.setItem('seq_id', JSON.stringify(list))
		}
		return id
	}
	, postEmoji: function(input_data){
		try{
			let list = []
			let result = this.getSampleData({type: 'bbs_emoji'})

			if(result.success) {
				list = result.data
				for(let i of list){
					if(i.bbs_id == input_data.bbs_id && i.user_id == input_data.user_id){
						i.code = input_data.code
						i.updated_at = this.getToday()

						localStorage.setItem('bbs_emoji', JSON.stringify(list))
						return this.returnResult(true, i, false)
					}
				}
			}

			let id = this.getSeqId('bbs_emoji')
			let data = {
				id: id
				, cartel_id: input_data.cartel_id
				, bbs_code: input_data.bbs_code
				, bbs_id: input_data.bbs_id
				, user_id: input_data.user_id
				, user_nick: input_data.user_nick
				, user_img_src: input_data.user_img_src
				, code: input_data.code
				, created_at: this.getToday()
			}

			list.unshift(data)

			localStorage.setItem('bbs_emoji', JSON.stringify(list))

			return this.returnResult(true, data, false)

		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	, postVote: function(input_data){
		try{
			let list = []
			let result = this.getSampleData({type: 'vote_list'})

			if(result.success) {
				list = result.data
			}

			let id = this.getSeqId('vote_id')
			if(!Array.isArray(input_data.votes)){
				input_data.votes = [input_data.votes]
			}
			for(let i = 0; i < input_data.votes.length; i++){
				let data = {
					id: id
					, cartel_id: input_data.cartel_id
					, bbs_id: input_data.bbs_id
					, user_id: input_data.user_id
					, user_nick: input_data.user_nick
					, user_img_src: input_data.user_img_src
					, votes: input_data.votes[i]
					, created_at: this.getToday()
				}
				list.unshift(data)
			}

			localStorage.setItem('vote_list', JSON.stringify(list))

			result = this.getSampleData({ type: 'bbs'})
			if(result.success){
				for(let item of result.data){
					if(item.id == input_data.bbs_id){
						this.$log.console('item', item.vote.items, input_data.votes)
						for(let i = 0; i < input_data.votes.length; i++){
							if(item.vote.items[input_data.votes[i]].count){
								item.vote.items[input_data.votes[i]].count++
							}else{
								item.vote.items[input_data.votes[i]].count = 1
							}
						}
						localStorage.setItem('bbs', JSON.stringify(result.data))
					}
				}
			}
			return this.returnResult(true, false, false)

		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	,postReportBbs: function(input_data){
		try{
			let list = []
			let result = this.getSampleData({type: 'bbs_report'})

			if(result.success) {
				list = result.data
			}

			let id = this.getSeqId('bbs_report')
			let data = {
				id: id
				, cartel_id: input_data.cartel_id
				, bbs_code: input_data.bbs_code
				, bbs_id: input_data.bbs_id
				, user_id: input_data.user_id
				, code: input_data.code
				, created_at: this.getToday()
			}

			list.unshift(data)

			localStorage.setItem('bbs_report', JSON.stringify(list))

			return this.returnResult(true, data, false)

		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	, postBlockMember: function(input_data){
		let sample_type = 'black_list'
		try{
			let list = []
			let result = this.getSampleData({type: sample_type})

			if(input_data.is_delete){
				return this.deleteItem(sample_type, input_data.id)
			}

			if(result.success) {
				list = result.data
			}

			let id = this.getSeqId(sample_type)
			let data = {
				id: id
				, user_id: input_data.user_id
				, black_id: input_data.black_id
				, created_at: this.getToday()
			}

			list.unshift(data)

			localStorage.setItem(sample_type, JSON.stringify(list))

			return this.returnResult(true, data, false)

		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	, deleteComment: function(item_comment){
		let sample_type = 'bbs_comment'
		try{
			let result = localStorage.getItem(sample_type)
			if(result){
				result = JSON.parse(result)
				for(let item of result){

					if(item.id == item_comment.id){
						item.is_delete = true
						localStorage.setItem(sample_type, JSON.stringify(result))
						return this.returnResult(true, false ,false)
					}
				}

				throw 'not match'
			}else{
				throw 'no data'
			}
		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	,postReportComment: function(input_data){
		let sample_type = 'comment_report'
		try{
			let list = []
			let result = this.getSampleData({type: sample_type})

			if(result.success) {
				list = result.data
			}

			let id = this.getSeqId(sample_type)
			let data = {
				id: id
				, cartel_id: input_data.cartel_id
				, bbs_code: input_data.bbs_code
				, bbs_id: input_data.bbs_id
				, comment_id: input_data.comment_id
				, user_id: input_data.user_id
				, code: input_data.code
				, created_at: this.getToday()
			}

			list.unshift(data)

			localStorage.setItem(sample_type, JSON.stringify(list))

			return this.returnResult(true, data, false)

		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	, postCheckEmail: function(input_data){
		let sample_type = 'account'
		try{
			let result = this.getSampleData({type: sample_type, key: 'email', val: input_data.email})

			if(result.success) {
				throw 'already'
			}else{
				return this.returnResult(true, false, false)
			}

		}catch(e){
			this.$log.console(e)
			return this.returnResult(false ,false, e)
		}
	}
	, postDeviceToken: function(token){
		try{
			localStorage.setItem('device_token', token)

			return this.returnResult(true, false, false)
		}catch (e){
			return this.returnResult(false ,false, e)
		}

	}
	,drops_config: {
		nft: {
			mafi: {
				price: 11.0
				, fee: 0.11
			}
			, klay: {
				price: 1.0
				, fee: 0.01
			}
		}
		, utility: {
			mafi: {
				price: 1.0
				, fee: 0.01
			}
			, klay: {
				price: 0.11
				, fee: 0.011
			}
		}
	}
	,currency: [
		{ id: 1, name: 'MAFI', label: 'mafi', fixed: 2, is_own: false, img_src: 'coin_mafi.png'}
		, { id:2, name: 'KLAY', label: 'klay', fixed: 8, is_own: false, img_src: 'coin_klay.png'}
	]
	,wallet_address: [
		{ id: 1, name: 'MAFI', label: 'mafi', address: 'mafi_address'}
		, { id:2, name: 'KLAY', label: 'klay', address: 'klay_address'}
	]
	,card_nft_list:[
		{ id: 1, card:'nft', contract: 'dummy_contract_1', price: 1.0, name: '오솔레미오-m', img_src: 'card1.png', summary: 'nft card 1 music', type: 'music'}
		, { id: 2, card:'nft', contract: 'dummy_contract_nft_2', price: 1.1, name: '오솔레미오-a', img_src: 'intro_character_1.png', summary: 'nft card 2 artist', type: 'art'}
		, { id: 3, card:'nft', contract: 'dummy_contract_nft_3', price: 1.2, name: '오솔레미오-f', img_src: 'intro_character_2.png', summary: 'nft card 3 fashioner', type: 'fashion'}
		, { id: 4, card:'nft', contract: 'dummy_contract_nft_4', price: 1.3, name: '오솔레미오-i', img_src: 'intro_character_3.png', summary: 'nft card 4 influencer', type: 'influence'}
		, { id: 5, card:'nft', contract: 'dummy_contract_nft_5', price: 1.4, name: '오솔레미오5', img_src: 'intro_character3_none.png', summary: 'nft card 5 가수', type: 'music'}
	]
	, card_utility_list: [
		{ id: 11, card:'util', contract: 'dummy_contract_util_11', price: 0.10, fee: 0.001, name: 'utility-1', img_src: 'utill_card1.png', summary: '유틸리티 카드 1', type: 'utility'}
		, { id: 12, card:'util', contract: 'dummy_contract_util_12', price: 0.11, fee: 0.001, name: 'utility-2', img_src: 'utill_card1.png', summary: '유틸리티 카드 2', type: 'utility'}
		, { id: 13, card:'util', contract: 'dummy_contract_util_13', price: 0.12, fee: 0.001, name: 'utility-3', img_src: 'utill_card1.png', summary: '유틸리티 카드 3', type: 'utility'}
		, { id: 14, card:'util', contract: 'dummy_contract_util_14', price: 0.13, fee: 0.001, name: 'utility-4', img_src: 'utill_card1.png', summary: '유틸리티 카드 7', type: 'utility'}
	]
	, card_all_list: [
		{ id: 1, card:'nft', contract: 'dummy_contract_1', price: 1.0, name: '오솔레미오-m', img_src: 'card1.png', summary: 'nft card 1 music', type: 'music'}
		, { id: 2, card:'nft', contract: 'dummy_contract_nft_2', price: 1.1, name: '오솔레미오-a', img_src: 'intro_character_1.png', summary: 'nft card 2 artist', type: 'art'}
		, { id: 3, card:'nft', contract: 'dummy_contract_nft_3', price: 1.2, name: '오솔레미오-f', img_src: 'intro_character_2.png', summary: 'nft card 3 fashioner', type: 'fashion'}
		, { id: 4, card:'nft', contract: 'dummy_contract_nft_4', price: 1.3, name: '오솔레미오-i', img_src: 'intro_character_3.png', summary: 'nft card 4 influencer', type: 'influence'}
		, { id: 5, card:'nft', contract: 'dummy_contract_nft_5', price: 1.4, name: '오솔레미오5', img_src: 'intro_character3_none.png', summary: 'nft card 5 가수', type: 'music'}
		, { id: 11, card:'util', contract: 'dummy_contract_util_11', price: 0.10, name: 'utility-1', img_src: 'utill_card1.png', summary: '유틸리티 카드 1', type: 'utility'}
		, { id: 12, card:'util', contract: 'dummy_contract_util_12', price: 0.11, name: 'utility-2', img_src: 'utill_card1.png', summary: '유틸리티 카드 2', type: 'utility'}
		, { id: 13, card:'util', contract: 'dummy_contract_util_13', price: 0.12, name: 'utility-3', img_src: 'utill_card1.png', summary: '유틸리티 카드 3', type: 'utility'}
		, { id: 14, card:'util', contract: 'dummy_contract_util_14', price: 0.13, name: 'utility-4', img_src: 'utill_card1.png', summary: '유틸리티 카드 7', type: 'utility'}
	]
	,agentList:[
		{
			"agentMarginRate": [
				"3.3%", '3.1%', '3.2%'
			],
			"agentSeq": "agentSeq",
			"agentTypeName": "리셀러",
			"businessName": "대리점 > 가맹점 상호명",
			"ceoName": "홍길동",
			"companyTypeName": "법인",
			"joinDate": "string",
			"managerEmail": "string",
			"managerMobilPhoneNumber": "string",
			"managerName": "string",
			"phoneNumber": 123345677,
			"rowNumber": 3,
			"status": "1",
			"statusName": "string"
		}
		,{
			"agentMarginRate": [
				"3.3%", '3.1%', '3.2%'
			],
			"agentSeq": "agentSeq",
			"agentTypeName": "리셀러",
			"businessName": "대리점 > 가맹점 상호명",
			"ceoName": "홍길동",
			"companyTypeName": "법인",
			"joinDate": "string",
			"managerEmail": "string",
			"managerMobilPhoneNumber": "string",
			"managerName": "string",
			"phoneNumber": 123345677,
			"rowNumber": 2,
			"status": "2",
			"statusName": "string"
		},{
			"agentMarginRate": [
				"3.3%", '3.1%', '3.2%'
			],
			"agentSeq": "agentSeq",
			"agentTypeName": "리셀러",
			"businessName": "대리점 > 가맹점 상호명",
			"ceoName": "홍길동",
			"companyTypeName": "법인",
			"joinDate": "string",
			"managerEmail": "string",
			"managerMobilPhoneNumber": "string",
			"managerName": "string",
			"phoneNumber": 123345677,
			"rowNumber": 1,
			"status": "3",
			"statusName": "string"
		}
	]
	,franchiseeList: [
		{
			"businessName": "대리점 > 가맹점 상호명",
			"ceoName": "홍길동",
			"companyTypeName": "개인",
			"feeRate": "feeRate",
			"joinDate": "joinDate",
			"marginFeeRate": "marginFeeRate",
			"pgMerchNo": "1",
			"rowNumber": 9,
			"servicesTypeName": "servicesTypeName",
			"settlementCycleName": "settlementCycleName",
			"status": "2",
			"statusName": "statusName",
			"totalPaymentAmount": "totalPaymentAmount",
			"yearLimit": "yearLimit",
			"yearLimitRemainingLimit": "yearLimitRemainingLimit"
		}
		,{
			"businessName": "대리점 > 가맹점 상호명",
			"ceoName": "홍길동",
			"companyTypeName": "비사업자",
			"feeRate": "feeRate",
			"joinDate": "joinDate",
			"marginFeeRate": "marginFeeRate",
			"pgMerchNo": "2",
			"rowNumber": 8,
			"servicesTypeName": "servicesTypeName",
			"settlementCycleName": "settlementCycleName",
			"status": "3",
			"statusName": "statusName",
			"totalPaymentAmount": "totalPaymentAmount",
			"yearLimit": "yearLimit",
			"yearLimitRemainingLimit": "yearLimitRemainingLimit"
		}
	]
	
	,franchiseeConfirmList: [
		{
			"businessName": "대리점 > 가맹점 상호명",
			"ceoName": "홍길동",
			"companyTypeName": "법인",
			"createAt": "2020-12-09T05:32:04.931Z",
			"id": 3,
			"rowNumber": 3,
			"serviceStatus": "NotApplied"
			,active: false
		}
		,
		{
			"businessName": "대리점 > 가맹점 상호명",
			"ceoName": "홍길동",
			"companyTypeName": "개인",
			"createAt": "2020-12-09T05:32:04.931Z",
			"id": 2,
			"rowNumber": 2,
			"serviceStatus": "NotApplied"
			,active: false
		}
		,
		{
			"businessName": "대리점 > 가맹점 상호명",
			"ceoName": "홍길동",
			"companyTypeName": "비사업자",
			"createAt": "2020-12-09T05:32:04.931Z",
			"id": 1,
			"rowNumber": 1,
			"serviceStatus": "cancel"
			,active: false
		}
	]
	
	,franchiseeItem: {
		"content": {
			"basic": {
				"joinDate": "joinDate",
				"userId": "userId",
				"userPhoneNumber": "userPhoneNumber",
				"userSeq": "userSeq"
			},
			"company": {
				"businessName": "businessName",
				"businessNumber": "businessNumber",
				"businessTypeCate": "businessTypeCate",
				"ceoMobilPhoneNumber": "ceoMobilPhoneNumber",
				"ceoName": "ceoName",
				"companyAddressDetail": 'companyAddressDetail',
				"companyAddressMain": "companyAddressMain",
				"companyTypeName": "companyTypeName",
				"companyZipCode": 'companyZipCode',
				"servicesTypeName": "servicesTypeName"
			},
			"insurance": {
				"insuranceCoverageAmount": "insuranceCoverageAmount",
				"monthLimit": "monthLimit",
				"oneTimeLimit": "oneTimeLimit",
				"yearLimit": "yearLimit"
			},
			"manager": {
				"managerEmail": "managerEmail",
				"managerMobilPhoneNumber": "managerMobilPhoneNumber",
				"managerName": "managerName"
			},
			"paymentItems": [
				{
				"agentMarginRate": "agentMarginRate",
				"dealerMarginRate": "dealerMarginRate",
				"merchantFeeRate": "merchantFeeRate",
				"resellerMarginRate": "resellerMarginRate",
				"serviceItemName": "serviceItemName",
				"supplyRate": "supplyRate"
				}
			],
			"settlement": {
				"bankAccountHolder": "bankAccountHolder",
				"bankAccountNumber": "bankAccountNumber",
				"bankCode": "bankCode",
				"settlementCycleName": "settlementCycleName"
			}
		},
		"message": "string",
		"status": 0,
		"timestamp": 0
	}
	
	,agentItem: {
		"content": {
			"basic": {
				"joinDate": "joinDate",
				"userId": "userId",
				"userPhoneNumber": "userPhoneNumber",
				"userSeq": "userSeq"
				,"status": 'apply'
				,"statusName": '정상사용'
			},
			"company": {
				"businessName": "businessName",
				"businessNumber": "businessNumber",
				"businessTypeCate": "businessTypeCate",
				"ceoMobilPhoneNumber": "ceoMobilPhoneNumber",
				"ceoName": "ceoName",
				"companyAddressDetail": 'companyAddressDetail',
				"companyAddressMain": "companyAddressMain",
				"companyTypeName": "companyTypeName",
				"companyZipCode": 'companyZipCode',
				"servicesTypeName": "servicesTypeName"
			},
			"insurance": {
				"insuranceCoverageAmount": "insuranceCoverageAmount",
				"monthLimit": "monthLimit",
				"oneTimeLimit": "oneTimeLimit",
				"yearLimit": "yearLimit"
			},
			"manager": {
				"managerEmail": "managerEmail",
				"managerMobilPhoneNumber": "managerMobilPhoneNumber",
				"managerName": "managerName"
			},
			"paymentItems": [
				{
				"agentMarginRate": "agentMarginRate",
				"dealerMarginRate": "dealerMarginRate",
				"merchantFeeRate": "merchantFeeRate",
				"resellerMarginRate": "resellerMarginRate",
				"serviceItemName": "serviceItemName",
				"supplyRate": "supplyRate"
				}
			],
			"settlement": {
				"bankAccountHolder": "bankAccountHolder",
				"bankAccountNumber": "bankAccountNumber",
				"bankCode": "bankCode",
				"settlementCycleName": "settlementCycleName"
			}
		},
		"message": "string",
		"status": 0,
		"timestamp": 0
	}
	
	,user: [
		{
			"joinDate": "string",
			"userId": "reappay",
			"userName": "립페이 대리점",
			"userSeq": "1",
			"userGrade": '1'
		}
		,{
			"joinDate": "string",
			"userId": "reappay",
			"userName": "립페이 리셀러",
			"userSeq": "2",
			"userGrade": '2'
		}
		,{
			"joinDate": "string",
			"userId": "reappay",
			"userName": "립페이 딜러",
			"userSeq": "3",
			"userGrade": '3'
		}
	]
	,notice: [
		{ id: 1, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일', is_new: true}
		, { id: 2, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일'}
		, { id: 3, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일'}
		, { id: 4, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일'}
	]
	,faq: [
		{
			id: 1, title: '자주 묻는 질문', items: [
				{ id: 1, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일', is_new: true}
				, { id: 2, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일'}
				]
		}
		,{
			id: 2, title: '회원 가입', items: [
				{ id: 1, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일', is_new: true}
				, { id: 2, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일'}
			]
		}
		,{
			id: 3, title: '카르텔', items: [
				{ id: 1, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일', is_new: true}
				, { id: 2, title: '아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내', content: '<p>안녕하세요.<br>아티스트 팬클럽 공식 커뮤니티 소유권 이관에 따른 이용약관 변경 및 개인정보 이전 안내를 드립니다.</p>', img_src: '@thoumb_notice.png', created_at: '2022년 4월 12일'}
			]
		}
	]

	, friends: [
	]
	
	,status: {
		confirm: [
			{
				code: ''
				,name: '전체'
			}
			,{
				code: '1'
				,name: '심사대기'
			}
			,{
				code: '2'
				,name: '재심사'
			}
			,{
				code: '3'
				,name: '반려'
			}
		]
	}
	
	,dashboard: {
		monthly:[
			{
				month: 1
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 2
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 3
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 4
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 5
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 6
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 7
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 8
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 9
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 10
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
			,{
				month: 11
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: true
			}
			,{
				month: 12
				,tot1: 100000
				,tot2: 200000
				,tot3: 300000
				,tot4: 10
				,tot5: 600000
				,active: false
			}
		]
	}
	, board_config: [
		{ code: 'notice', name: '공지사항', fav: 0, sort: 1}
		, { code: 'base1', name: '한줄 응원', fav: 0, sort: 2}
		, { code: 'base2', name: '일반게시판', fav: 1, sort: 3}
		, { code: 'base3', name: '뉴스', fav: 1, sort: 4}
		, { code: 'base4', name: 'NFT 민팅소개', fav: 1, sort: 5}
		, { code: 'base5', name: 'TO 스타', fav: 1, sort: 6}
		, { code: 'base6', name: 'FROM 스타', fav: 1, sort: 7}
		, { code: 'base7', name: 'NFT 자랑하기', fav: 1, sort: 8}
	]
	, cartel_stat: [
		{ id: 1, type: 'daily', data: [40, 39, 10, 40, 39, 80, 40]}
		, { id: 2, type: 'weekly', data: [140, 139, 110, 40, 319, 80, 401]}
		, { id: 3, type: 'monthly', data: [400, 390, 1000, 400, 309, 800, 400]}
	]
	, member_rank: [
		{
			id: 1, type: 'daily', data: [
				{"level": 1, "user_nick": "낭야1", "memo": "!", "user_id": 1, "user_rank": 1, "visit_count": 100, "new_count": 10},
				{"level": 2, "user_nick": "낭야2", "memo": "!", "user_id": 2, "user_rank": 2, "visit_count": 99, "new_count": 10},
				{"level": 3, "user_nick": "낭야3", "memo": "!", "user_id": 3, "user_rank": 3, "visit_count": 90, "new_count": 10},
				{"level": 4, "user_nick": "낭야4", "memo": "!", "user_id": 4, "user_rank": 4, "visit_count": 80, "new_count": 10},
				{"level": 5, "user_nick": "낭야5", "memo": "!", "user_id": 5, "user_rank": 5, "visit_count": 70, "new_count": 10},
				{"level": 4, "user_nick": "낭야6", "memo": "!", "user_id": 6, "user_rank": 6, "visit_count": 60, "new_count": 10},
				{"level": 3, "user_nick": "낭야7", "memo": "!", "user_id": 7, "user_rank": 7, "visit_count": 50, "new_count": 10},
				{"level": 2, "user_nick": "낭야8", "memo": "!", "user_id": 8, "user_rank": 8, "visit_count": 10, "new_count": 10}
			]
		}
		, {
			id: 2, type: 'weekly', data: [
				{"level": 1, "user_nick": "낭야1", "memo": "!", "user_id": 1, "user_rank": 1, "visit_count": 10, "new_count": 10},
				{"level": 2, "user_nick": "낭야2", "memo": "!", "user_id": 2, "user_rank": 2, "visit_count": 10, "new_count": 10},
				{"level": 3, "user_nick": "낭야3", "memo": "!", "user_id": 3, "user_rank": 3, "visit_count": 10, "new_count": 10},
				{"level": 3, "user_nick": "낭야7", "memo": "!", "user_id": 7, "user_rank": 4, "visit_count": 10, "new_count": 10},
				{"level": 2, "user_nick": "낭야8", "memo": "!", "user_id": 8, "user_rank": 5, "visit_count": 10, "new_count": 10}
			]
		}
		,{
			id: 3, type: 'monthly', data: [
				{"level": 1, "user_nick": "낭야1", "memo": "!", "user_id": 1, "user_rank": 1, "visit_count": 10, "new_count": 10},
				{"level": 2, "user_nick": "낭야2", "memo": "!", "user_id": 2, "user_rank": 2, "visit_count": 10, "new_count": 10},
				{"level": 3, "user_nick": "낭야3", "memo": "!", "user_id": 3, "user_rank": 3, "visit_count": 10, "new_count": 10},
				{"level": 4, "user_nick": "낭야4", "memo": "!", "user_id": 4, "user_rank": 4, "visit_count": 10, "new_count": 10},
			]
		}
	]

}