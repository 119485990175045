<template>
	<div
		:class="wrap" id="wrap"
		class="size-px-16"
	>
		<div class="popup_bg" v-if="is_on_popup"></div>

		<Header
			v-if="program.type != 'auth' && program.type != 'event'"
			:user="user"
			@to="to"
		></Header>

		<div
			:class="program.type == 'auth' ? 'wrap-auth' :  program.type == 'event' ? '' : 'wrap-container'"
		>
			<CartelTop
				v-if="is_cartel_top"
				:user="user"
				:cartel="program.cartel"
				class="wrap-banner-cartel bg-white"
			></CartelTop>

			<LayoutAuth
				v-else-if="program.type == 'auth'"
				class="auth-content"
			></LayoutAuth>

			<Banner
				v-else-if="is_banner"
				class="wrap-banner"
			></Banner>

			<div
				class="wrap-contents"
				:class="program.bg_contents"
			>
				<div
					class="contents position-relative"
				>
					<div
						v-if="is_cartel_setting"
						class="mt-30 text-left flex-row"
					>
						<CartelSetting
							:user="user"

							class="flex-side mr-30"

							@click="setMenu"
						></CartelSetting>

						<router-view
							:Axios="Axios"
							:Notify="Notify"
							:class="bg_router"
							:sample="sample"
							:callBack="returnCallBack"
							:rules="rules"
							:user="user"
							:Base64="Base64"

							@to="to"
							@onLoad="onLoad"
							@pinCheck="is_pin = true"
							@goBack="goBack"
							@onLoading="onLoading"
							@offLoading="offLoading"

							@onPopup="is_on_popup = true"
							@offPopup="is_on_popup = false"
							@changePinType="changePinTypeToLost"

							class="full-height pb-30"
						></router-view>
					</div>

					<router-view
						v-else
						:Axios="Axios"
						:Notify="Notify"
						:class="bg_router"
						:sample="sample"
						:callBack="returnCallBack"
						:rules="rules"
						:user="user"
						:Base64="Base64"

						@to="to"
						@onLoad="onLoad"
						@pinCheck="is_pin = true"
						@goBack="goBack"
						@onLoading="onLoading"
						@offLoading="offLoading"

						@onPopup="is_on_popup = true"
						@offPopup="is_on_popup = false"
						@changePinType="changePinTypeToLost"

						class="full-height pb-30"
					></router-view>

					<PIN
						v-if="is_pin"
						:user="user"
						:options="pin_option"

						@click="pinCallBack"
						@cancel="offPin"
						@logout="logout"
						ref="pin"
						@lostPinConfirm="lostPinConfirm"

						class="position-fixed-full z-index-pin"
					></PIN>
				</div>
			</div>
		</div>

		<Bottom
			v-if="!program.not_footer && program.type != 'popup'"
			:program="program"

			@to="to"
		></Bottom>

		<OTP
			v-if="is_otp"
			:options="otp_option"

			@click="otpCallBack"
			@cancel="offOtp"
		></OTP>

		<Unauthorized
			v-if="false"

			:user="user"

			class="layer-popup"
		></Unauthorized>

		<Notify
			:msg="notifyCondition.message"
			:type="notifyCondition.type"
			:config="notifyCondition.config"

			@clearMsg="clearMsg"
		/>

		<Loading></Loading>

		<Viewer
			v-if="is_viewer"
			:user="user"
			:file="item_viewer_file"

			@cancel="is_viewer = false"
		></Viewer>

		<PopupFriend
			v-if="is_on_popup_friend"
			:user="user"
			:item_friend="item_friend"
			:confirm_type="confirm_type"

			@cancel="is_on_popup_friend=false"
		></PopupFriend>

		<mafia098
			v-if="!is_on_popup_friend && is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_friend"

			@cancel="is_098 = false"
		></mafia098>

		<Event
			:user="user"
		></Event>

		<PersonalConfirm
			v-if="is_on_personal_confirm"
			:user="user"

			@cancel="is_on_personal_confirm = false"
			@click="toNext"
		></PersonalConfirm>

	</div>
</template>

<script>
	import Header from '@/view/Layout/Header'
	import Bottom from "@/view/Layout/Bottom";

	import Notify from '@/components/AlertMsg'

	import PIN from "@/view/Auth/mafia007"
	import Loading from "@/view/Layout/Loading";
	import OTP from "@/view/Auth/mafia111-6"

	import Unauthorized from "@/view/Error/Unauthorized"
	import Banner from "@/view/Layout/Banner";
	import LayoutAuth from "@/view/Layout/LayoutAuth";
	import CartelTop from "@/view/Cartel/CartelTop";
	import Viewer from "@/view/Layout/Viewer";
	import PopupFriend from "@/view/Layout/Popup/PopupFriend";
	import Mafia098 from "@/view/Cartel/mafia098";
	import Event from "@/view/Event/Event";
	import PersonalConfirm from "@/view/Layout/PersonalConfirm";
	import CartelSetting from "@/view/Cartel/CartelSetting";

	export default{
		name: 'Layout'
		,props: ['Axios', 'Notify', 'sample', 'rules', 'user', 'Base64']
		,components: {
			CartelSetting,
			PersonalConfirm,
			Event,
			Mafia098,
			PopupFriend, Viewer, CartelTop, LayoutAuth, Banner, Header, Bottom, Notify, PIN, Loading, OTP, Unauthorized }
		,data: function(){
			return {
				program: {
					cartel: {
						cartl_number: ''
						, cartl_web_img_url: ''
					}
				}
				, notifyCondition: {
					message: '메시지 입니다'
					,type: ''
				}
				, returnCallBack: {

				}
				, is_pin: false
				, is_on_popup: false
				, pin_option: {
					pin_type: 'check'
					, is_can_cancel: true
				}
				, is_otp: false
				, otp_option: {
					otp_type: 'confirm'
				}
				,is_loading: false
				, is_view : false
				, error_status: ''
				, is_401: false
				, is_viewer: false
				, item_viewer_file: {}
				, is_friend: false
				, item_friend: {

				}
				, lost_pin_item: {

				}
				, is_098: false
				, item_cartel: {

				}
				, is_on_popup_friend: false
				, confirm_type: ''
				, is_on_personal_confirm: false
				, personal_type: ''
				, item_menu: {}
			}
		}
		,computed: {
			wrap: function(){
				let type = 'wrap'
				if(this.program.wrap_type == 'flex'){
					type = 'wrap-flex'
				}
				let location = window.location.href

				if(location.indexOf('auth') > -1 && location.indexOf('Event') == -1){
					type += ' bg-auth'
				}

				return type
			}
			, is_sample: function(){
				let t = false
				if(process.env.VUE_APP_TYPE == 'sample'){
					t = true
				}
				return t
			}
			, bg_router: function(){
				let type = 'bg_dark'
				if(this.program.type == 'mypage'){
					type = ''
				}else if(this.program.type == 'cartel' || this.program.type == 'cartel_sub'){
					type = 'bg_wh'
				}
				return type
			}
			, is_banner: function(){
				let t = false
				if(this.program.name && this.program.type != 'auth' && this.program.type != 'mypage' && this.$route.path.indexOf('/cartel/') < 0){
					t = true
				}
				return t
			}
			, is_cartel_top: function(){
				let t = false
				// this.$log.console(this.$route.path)

				if(this.program.cartel && this.$route.params.idx && this.$route.path.indexOf('/cartel/') > -1){
					t = true
				}
				return t
			}
			, is_cartel_setting: function(){
				let t = false
				if(this.program.is_side){
					t = true
				}
				return t
			}
		}
		,methods: {

			clearMsg: function(){
				this.notifyCondition.message = ''
			}
			,to: async function({ name, child, path, params, query, not_query, hash }){

				document.body.scrollTop = 0

				this.$log.console('to params', name, this.$route.name)

				let router = this.$router.resolve({ name: name, params: params, query: query})

				if(child){
					this.$log.console('router', router)
				}

				if(!not_query) {
					localStorage.removeItem('query')
					this.query = {}
				}else{
					this.setQuery()
				}

				this.$log.console('router to ', name, path, router.href)
				await this.$router.push((path ? path : router.href) + (hash ? '#' + hash : '') +'?a=' + Math.random() )

				/*

				if(path){
					await this.$router.push({ path: path, params: params, query: query})
				}else{
					if(name == this.$route.name){

						this.$log.console('same!! to duplicate page ')
						if(params){
							params.name = name
						}else{
							params = {
								name: name
							}
						}

						await this.$router.push({ name: 'DuplicatePage', params: params, query: query})
					}else{
						this.$log.console(' name != ' + name)
						await this.$router.push({ name: name, params: params, query: query}).catch(function(e){
							this.$log.console(e)
						})
					}
				}

				 */
			}
			,setQuery: function(){
				let store = localStorage.getItem('query')
				if(store){
					let query = JSON.parse(store)
					this.query = query
				}
			}
			,onLoad: function(program){
				this.error_status = sessionStorage.getItem('Error')
				if(this.error_status){
					if(this.$route.fullPath.indexOf('/auth/') > -1){
						this.is_401 = false
					}else{
						this.is_401 = true
					}
				}
				// this.$log.console(`onload ${program.name}`, this.error_status, program)

				this.$set(this, 'program', program)
				this.$set(this.program, 'cartel', program.cartel)

			}
			,goBack: function(){
				// this.$log.console('goBack', this.program.from)

				if(this.program.from){

					this.$router.push({ name: this.program.from, params: this.program.params}).catch((e) => {
						if(this.program.from == 'mafia049'){
							this.$router.push({ name: 'mafia044'})
						}
						this.$log.console(e)
					})
				}else{
					this.$storage.clearQuery()
					this.query = {}
					this.$router.back()
				}
			}
			, pinCallBack: function(pin_number){
				this.is_pin = false
				this.pin_option.is_can_cancel = true
				this.$bus.$emit('pinCallback', this.pin_option.pin_type, pin_number)
			}
			,callBack: function(){
				this.returnCallBack = this.program.callBack
				this.is_pin = false
				this.$bus.$emit('callBack', this.returnCallBack)
			}
			,onLoading: function(){
				this.is_loading = true
			}
			,offLoading: function(){
				setTimeout(() => {
					this.is_loading = false
				}, 300)
			}
			, onPin: function(pin_type){
				if(pin_type == 'set'){
					this.pin_option.is_can_cancel = false
				}else{
					this.pin_option.is_can_cancel = true
				}
				this.pin_option.pin_type = pin_type
				this.is_otp = false
				this.is_pin = true
			}
			, offPin: function(){
				this.is_pin = false
				this.pin_option.pin_type = 'check'
				this.$bus.$emit('pinCancel')
				this.$bus.$off('pinCallback')
			}

			, otpCallBack: function(otp_numbr){
				this.$log.console('layout otpCallBack !!')
				this.$bus.$emit('otpCallBack', this.otp_option.otp_type, otp_numbr)
				this.offOtp()
			}
			, logout: function(){
				this.$bus.$emit('logout')
				this.offPin()
			}
			, onOtp: function(otp_type){
				if(this.is_otp){
					this.$log.console('layout otp is on already')
				}else{
					this.otp_option.otp_type = otp_type
					this.is_pin = false
					this.is_otp = true
				}
			}
			, offOtp: function(){
				this.is_otp = false
				this.$bus.$off('otpCallback')
			}

			, onViewer: function(file){
				this.is_viewer = true
				this.item_viewer_file = file
			}
			, changePinTypeToLost: function(item){
				this.pin_option.pin_type = 'lost'
				this.lost_pin_item = item
				// this.$log.console('lost pin item : ' + JSON.stringify(item))
			}

			, lostPinConfirm: async function(pinnumber = ''){
				await this.$refs.pin.postConfirm(this.lost_pin_item.certfc_number, this.lost_pin_item.certfc_request_key, pinnumber, this.lost_pin_item.mphone_number)
			}
			,clipBoard: function (val){
				const t = document.createElement("textarea");
				document.body.appendChild(t);
				t.value = val;
				t.select();
				document.execCommand('copy');
				document.body.removeChild(t);
			}

			,copy: function (val){
				this.clipBoard(val);
				this.$bus.$emit('notify',  { type: 'success', message: '클립보드에 복사되었습니다.'})
			}
			, toNext: function(){
				this.is_on_personal_confirm = false
				this.$bus.$emit('to', this.personal_type)
			}
			, setMenu: function(item){
				this.item_menu = item
				this.$bus.$emit('to', item.to)
			}

		}
		, created() {
			this.$log.console('on layout')

			this.$bus.$on('onLoad', async (program) => {
				this.onLoad(program)
			})
			this.$bus.$on('to', (to) => {
				this.$log.console('to on bus', to)
				this.to(to)
			})
			this.$bus.$on('onPin', (type) => {
				this.onPin(type)
			})
			this.$bus.$on('offPin', () => {
				this.offPin()
			})
			this.$bus.$on('onOtp', (type) => {
				if(this.is_otp){
					this.$log.console('layout bus otp is on !!')
				}else{
					this.$log.console('layout bus on otp')
					this.onOtp(type)
				}
			})
			this.$bus.$on('offOtp', () => {
				this.offOtp()
			})
			this.$bus.$on('viewer', (file) => {
				this.onViewer(file)
			})
			this.$bus.$on('clipboard', (val) => {
				this.copy(val)
			})

			this.$bus.$on('onPersonalConfirm', (type) => {
				this.is_on_personal_confirm = true
				this.personal_type = type
			})

			this.$bus.$on('onErrorImage', (e) => {
				e.target.src = require('@/assets/image/@noimage.png')
				e.target.style.background='#eee'
			})
			this.$bus.$on('onErrorVoteImage', (e) => {
				e.target.src = require('@/assets/image/web/notify_cartel/ico_voting_none.svg')
				e.target.style.background='#eee'
			})
			this.$bus.$on('onErrorProfileImage', (e) => {
				e.target.src = require('@/assets/image/web/mypage_cartel/@profile_inner.png')
			})
			this.$bus.$on('onErrorCartelProfileImage', (e) => {
				e.target.src = require('@/assets/image/logo-profile.png')
			})
			this.$bus.$on('onErrorCartelCharacterImage', (e) => {
				e.target.src = require('@/assets/image/@cartel_cover_basic.png')
			})


			this.$bus.$on('onFriend', (e) => {
				this.$log.console('onFriend', e)

				this.is_on_popup_friend = true
				this.confirm_type = e.type
				this.item_friend = e.item_friend
			})

			this.$bus.$on('onProfile', (item_cartel, item_target) => {
				this.is_098 = true
				if(item_cartel.cartl_number){
					this.item_cartel = item_cartel
				}
				this.item_friend = item_target

				this.$log.console('onProfile', this.item_cartel, item_target)
			})

			this.$bus.$on('offProfile', () => {
				this.is_098 = false
			})

			window.onPin = () => {
				this.$log.console('window.onPin')
				try{
					if(this.user.member_number){
						this.pin_option.is_can_cancel = false
						this.onPin('check')
					}
				}catch (e) {
					this.$log.console(e)
				}
			}
			window.goBack = () => {
				this.$log.console('window.goBack')
				try{

					let dir = this.$route.name
					this.$log.console('dir: ', dir)
					this.goBack()
				}catch (e) {
					this.$log.console(e)
				}
			}

			if(!window.Kakao.isInitialized()){
				window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			}
		}
	}
	
</script>

<style>
	.wrap {

	}
	.wrap-body {
		width: 100%;
		overflow: hidden;
		text-align: center !important;
		margin: 0 auto;
	}

	.wrap-banner-cartel {

		width: 100%;
		height: 360px;
		overflow: hidden;
	}

	.wrap-banner {
		width: 100%;
		height: 380px;
		overflow: hidden;
	}

	.wrap-container {
		overflow: hidden;
		margin-top: 80px;
	}

	.wrap-contents {
		overflow: hidden;
		background-color: white;
	}

	.wrap-contents .contents {
		width: 1200px;
		text-align: center;
		margin: 0 auto;
	}

	.wrap-auth {
		width: 1200px; height: 100%;
		margin: 0 auto;
		display: flex;
	}

	.wrap-auth .contents {
		width: 500px; min-height: 800px; height: 100%;
		text-align: center;
		margin: 0;
		flex-basis: 500px;
		overflow: hidden;
	}


  .popup_bg {
    opacity: 80%;
    background: black;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100000;
  }
</style>