<template>
	<div
		class="position-fixed-full radius-20 z-index-layer overflow-hidden"
	>
		<div class="bg-layer"></div>
		<div
			class="position-relative viewer flex-column justify-center full-width full-height"
			style="z-index: 99999;"
		>
			<div
				style="max-height: 80%;"
			>
				<slot name="body"></slot>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'PopupLayer'
}
</script>