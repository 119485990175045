<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="flex-column radius-20 text-left  radius-20 overflow-hidden"
				style="min-width: 240px; max-width: 480px; margin: 0 auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>

				<div class="bg-mafia-blue flex-row justify-space-between items-center pa-10-20">
					<h4 class="color-white ml-10">작성글</h4>
					<button
						@click="$emit('cancel')"
					><v-icon class="color-white">mdi-close-circle</v-icon></button>
				</div>

				<div class="bg-white pa-30">
					<div
						class="flex-column items-center"
						style="max-height: 240px"
						ref="post_list_item_view"
					>
						<table class="nft_info_table">
							<colgroup>
								<col width="75%">
								<col width="25%">
							</colgroup>
							<tbody>
							<template
								v-if="items.length > 0"
							>
								<tr
									v-for="(item, index) in items"
									:key="'item_' + index"
								>
									<td @click="toDetail(item)">{{ item.contents | maxLength(10, '...') }}</td>
								</tr>
							</template>
							<tr
								v-else
							>
								<td colspan="3" class="td_list_none">
									<div class="list_none">
										<img :src="require('@/assets/image/list_none.png')" alt="이력없음">
										<span>등록된 게시물이 없습니다.</span>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia0982'
	,
	components: {PopupLayer},
	props: ['user', 'member', 'cartel']
	, data: function(){
		return {
			program: {
				name: 'STAKING 내역'
			}
			, items: []
			, item_search: this.$storage.init({
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, max: false
			, is_add: false
			, body: ''
		}
	}
	,methods: {
		getData: async function(page = 1){
			try{
				this.$bus.$emit('on', true)
				this.$set(this.item_search, 'page_number', page)
				this.is_add = true
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_comm_cartel_board_list
					, data: {
						member_number: this.user.member_number
						, inquiry_member_number: this.member.member_number ? this.member.member_number : this.member.post_member_number
						, cartl_number: this.cartel.cartl_number
						, srchtext: ''
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){

					if(result.data.post_list.length > 0){
						this.items = this.items.concat(result.data.post_list)
						this.max = false
					}else{
						this.max = true
					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'mafia058', params: {idx: this.$route.params.idx, from: this.$route.name, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, scrollListen: async function () {

			if(!this.is_add){
				return false
			}

			let w = this.body.scrollBody
			let b = this.body.scrollHeight
			let t = this.body.scrollTop

			if(this.max){
				return false
			}

			let max = b - t
this.$log.console(w, b, t, max)
			if (max <= 280 ) {
				// this.$log.console(`w: ${w}, b: ${b}, m: ${max}, t: ${t}, type: ${this.type}, this.page : ${ this.item_search.page_number }`)

				await this.getData(this.item_search.page_number + 1)
			}
		}
		, removeScroll: function () {
			this.$log.console('removeScroll')
			this.body.removeEventListener('scroll', this.scrollListen)
		}
		, init: function(){
			this.body = this.$refs.post_list_item_view
			this.body.addEventListener('scroll', this.scrollListen);
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.$log.console('member', this.member)
		this.getData()

		setTimeout( () =>{
			this.init()
		}, 100)
	}
}
</script>