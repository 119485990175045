<template>
	<div
		ref="marquee-wrap"
		class="marquee-wrap position-relative"
	>
		<div
			ref="marquee"
			class="justify-space-between items-center overflow-hidden"
			style="left:0; top: 0"
			:class="{ marquee: is_marquee }"
		>
			<div ref="marquee-main" style="flex-shrink: 0; min-width: 100%;" class="marquee-txt" :class="{'mr-30': is_marquee, 'text-center': center}" ><slot name="text"></slot></div>
			<div
				v-if="is_marquee"
				ref="marquee-sub"
				style="flex-shrink: 0"
				class="marquee-txt mr-30"
			><slot name="text"></slot></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Marquee'
		, props: ['center']
		, data: function(){
			return {
				is_marquee: false
			}
		}
		, computed:{
		}
		, created() {
			setTimeout(() => {
				let wrap = this.$refs["marquee-wrap"].clientWidth
				let marquee = this.$refs["marquee-main"].clientWidth
				if(marquee > wrap){
					this.is_marquee = true


					setTimeout( () =>{
						this.$refs["marquee-main"].style.animation = 'scroll ' + (marquee / 15) + 's linear 3s infinite'
						this.$refs["marquee-sub"].style.animation = 'scroll ' + (marquee / 15) + 's linear 3s infinite'
					}, 100)


				}

			}, 100)
		}
	}
</script>

<style>
.marquee {
	height: 1rem;
	overflow: hidden;
	position: relative;
	/*
	border: 1px solid #4a4a4a;
	*/
}

.marquee-txt {
	height: 100%;
	margin: 0;
	line-height: 1rem;
	text-align: left;
}

.marquee > .marquee-txt {
	height: 100%;
	margin: 0;
	line-height: 1rem;
	text-align: left;

}

@-moz-keyframes scroll-left {
	0% {
		-moz-transform: translateX(0%);
	}
	100% {
		-moz-transform: translateX(-100%);
	}
}

@-webkit-keyframes scroll-left {
	0% {
		-webkit-transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(-100%);
	}
}

@keyframes scroll-left {
	0% {
		-moz-transform: translateX(0%);
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
	100% {
		-moz-transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}


@keyframes scroll {
	0% {
		-moz-transform: translateX(0%);
		-webkit-transform: translateX(0%);
		transform: translateX(0);
	}

	75% {
		-moz-transform: translateX(calc(-100% - 30px));
		-webkit-transform: translateX(calc(-100% - 30px));
		transform: translateX(calc(-100% - 30px));
	}
	100% {
		-moz-transform: translateX(calc(-100% - 30px));
		-webkit-transform: translateX(calc(-100% - 30px));
		transform: translateX(calc(-100% - 30px));
	}
}
</style>