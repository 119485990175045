<template>
    <PopupLayer>
		<template
			v-slot:body
		>
        <div class="width-400 ma-auto radius-20 bg-black color-white text-center">
            <div class="flex-1 flex-column justify-center pa-30">
                <div>
                    <img :src="require('@/assets/image/web/ico_logo_small.svg')"/>
                </div>
                <div class="text_guide mt-30">
                    <h4 class="color-white">{{ pin_text }}</h4>
                </div>
                <div class="flex-row justify-space-around pa-40 mb-30">
                    <div
                        v-for="(item, index) in pinForType"
                        :key="'pin_' + index"
                        class="word"
                        :class="{ on: item }"
                    ></div>
                </div>
            </div>

            <div id="alert-wrap" class="alert-wrap" style="position: absolute" v-html="message">
                <!--			<div class="alert-content bg-red"><i class="icon-alert-fail"></i> <span>"+'테스트'+"</span></div>-->
            </div>

            <div class="password_keyboard flex-column justify-space-around keyboard-radius">
                <table class="keyboard">
                    <tbody>
                        <tr
                            v-for="num in 3"
                            :key="'num_' + num"
                        >
                            <td
                                v-for="num_s in 3"
                                :key="'num_' + num + '_' + num_s"
                            >
                                <button type="button" class="key" @click="setPin(num_s + (3 * (num - 1)))">
                                    <span>{{ num_s + (3 * (num - 1)) }}</span>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button
                                    v-if="is_can_cancel"
                                    type="button" class="key size-px-18" @click="cancel"
                                >
                                취소
                                </button>
                                </td>
                                <td>
                                    <button type="button" class="key" @click="setPin(0)">
                                        <span>0</span>
                                    </button>
                                </td>
                                <td>
                                    <button type="button" class="key" @click="back()">
                                        <span class="ico_del"><em class="hide">지우기</em> <img :src="require('@/assets/image/web/intro/ico_reset.svg')" /></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        v-if="is_change"
                        class="find_link "
                    >
                        <button @click="onOtp" class="color-white">
                            혹시 PIN번호를 잊으셨나요? <i class="icon-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </PopupLayer>
</template>


<script>

import PopupLayer from "@/view/Layout/PopupLayer";
export default {
    name: 'PIN'
    , props: ['options', 'user']
    , components: {PopupLayer}
    , data: function () {
        return {
            message: '',
            program: {
                name: '핀 보안'
                , not_header: true
                , not_footer: true
                , wrap_type: 'felx'
            }
            , step: 0
            , step_confirm: 0
            , max: 5
            , pin: [false, false, false, false, false, false]
            , pin_confirm: [false, false, false, false, false, false]
            , is_confirm: false
            , is_otp: false
            , is_can_cancel: this.options.is_can_cancel
        }
    }
    , computed: {

        pinForType: function () {

            if (this.is_confirm) {
                return this.pin_confirm
            } else {
                return this.pin
            }
        }
        , pin_type: function () {
            let type = 'set'

            if (this.options) {
                type = this.options.pin_type
            } else if (this.$route.params.pin_type) {
                type = this.$route.params.pin_type
            }

            this.$log.console('pin_type', type)
            return type
        }
        , is_change: function () {
            let t = false

            //if(this.pin_type == 'check' || this.pin_type == 'pin_login'){
            if (this.pin_type == 'pin_login') {
                t = true
            }

            return t
        }
        , pin_text: function () {
            let t = ''

            if (this.pin_type == 'set') {
                if (this.is_confirm) {
                    t = 'PIN번호를 한번 더 입력하세요'
                } else {
                    t = '보안을 위해 PIN번호를 설정하세요'
                }
            } else if (this.pin_type == 'update') {
                if (this.is_confirm) {
                    t = 'PIN번호를 한번 더 입력하세요'
                } else {
                    t = '신규 PIN번호를 입력하세요'
                }
            } else if (this.pin_type == 'lost') {
                if (this.is_confirm) {
                    t = 'PIN번호를 한번 더 입력하세요'
                } else {
                    t = '신규 PIN번호를 입력하세요'
                }
            } else if (this.pin_type == 'join') {
                if (this.is_confirm) {
                    t = 'PIN번호를 한번 더 입력하세요'
                } else {
                    t = '신규 PIN번호를 입력하세요'
                }
            } else {
                t = '보안 PIN번호를 입력하세요.'
            }

            return t
        }
    }
    , methods: {

        setPin: function (number) {
            // this.$log.console(number)
            if (this.is_confirm) {
                if (!this.pin_confirm[this.step_confirm]) {
                    this.$set(this.pin_confirm, this.step_confirm, number + '')
                    this.step_confirm++
                }
            } else {
                if (!this.pin[this.step]) {
                    this.$set(this.pin, this.step, number + '')
                    this.step++
                }
            }
        }
        , back: function () {
            if (this.is_confirm) {
                this.step_confirm--
                this.$set(this.pin_confirm, this.step_confirm, false)
            } else {
                this.step--
                this.$set(this.pin, this.step, false)
            }
        }
        , reset: function () {
            this.is_confirm = false
            this.step = 0
            this.step_confirm = 0
            this.pin = [false, false, false, false, false, false]
            this.pin_confirm = [false, false, false, false, false, false]
        }
        , clear: function () {
            if (this.is_confirm) {
                this.pin_confirm = [false, false, false, false, false, false]
                this.step_confirm = 0
            } else {
                this.pin = [false, false, false, false, false, false]
                this.step = 0
            }
        }
        , cancel: function () {

            let currentPath = this.$route.path

            if (currentPath.startsWith('/auth/pin_sms')) {
                this.$emit('cancel')
                this.$router.replace({name : 'login'})
            } else {
                this.$log.console('this.pin_type', this.pin_type)
                switch (this.pin_type) {
					default:
					case 'set': case 'lost': case 'join': case 'pin_login':
						this.clear()
						this.$emit('logout')
						break
					case 'update':
						this.$emit('cancel')
						break
					case 'check':
						this.$emit('cancel')
						break
                }
            }
        }


        /*
        * MEM-04-003 핀번호 저장 요청
        * 회원가입시 요청
        *
        * */
        , postPin: async function () {
            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_pin
                    , data: {
                        pinnumber: this.pin.toString().replaceAll(',', '')
                        , confirmation_pinnumber: this.pin_confirm.toString().replaceAll(',', '')
                    }
                    , type: true
                })

                if (result.success) {
                    this.$bus.$emit('getDeviceToken')
                    this.$emit('click', this.pin.toString().replaceAll(',', ''))
                } else {
                    throw result.message
                }

            } catch (e) {
                this.$log.console(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.reset()
            }
        }
        /*
        * MEM-22-003 핀번호 분실 저장
        *
        * */

        , postPinBeforLogin: async function () {
            this.$log.console('postPinBeforLogin')

            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_pin_befor_login
                    , data: {
                        pinnumber: this.pin.toString().replaceAll(',', '')
                        , confirmation_pinnumber: this.pin_confirm.toString().replaceAll(',', '')
                    }
                    , type: true
                })

                if (result.success) {
                    this.$emit('click')
                    this.$bus.$emit('getDeviceToken')
                } else {
                    throw result.message
                }

            } catch (e) {
                this.$log.console(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.reset()
            }
        }
        /*
        * MEM-05-002 로그인 핀번호 확인 요청
        *
        * */
        , postPinLogin: async function () {
            this.$log.console('postPinLogin')

			let certfc = this.$encodeStorage.getItem('login_info')
			let pin_token = this.$encodeStorage.getItem('pin_token')
			let pin_sesison_token = this.$encodeStorage.getItem('pin_session_token')
			let url = this.$api_url.api_path.post_pin_login
			let data = {
				pinnumber: this.pin.toString().replaceAll(',', '')
			}

			if(certfc && certfc.member_number && pin_token){
				url = this.$api_url.api_path.post_pin_token_login
				data.member_number = certfc.member_number
				data.pin_token = pin_token
			}else{
				await this.$encodeStorage.removeItem('pin_session_token')
			}

			if(pin_token && pin_sesison_token){
				data.pin_certfc_session_token = pin_sesison_token
			}

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: data
					,type: true
				})

				if(result.success){


					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
					await this.$encodeStorage.setSessionAt(result.data)
					if(result.data.pin_token){
						await this.$encodeStorage.setItem('pin_token', result.data.pin_token)
					}
					await this.$encodeStorage.setItem('login_info', result.data)
					await this.$encodeStorage.removeItem('pin_session_token')

					//await this.$router.push({ name: 'main'})
					this.$bus.$emit('setUser', result.data)
					setTimeout( () => {
						this.$router.push({ name: 'main'})
					}, 500)
				}else{
					throw result
				}

			}catch(e){
				console.log(e)

				this.$bus.$emit('notify', { type: 'error', message: e.message})
				if(e.code == 'E000100032' || e.code == 'E000500013'){
					this.cancel()
				}else if(e.code == 'E000400038'){
					if(e.data.pin_certfc_session_token){
						await this.$encodeStorage.setItem('pin_session_token', e.data.pin_certfc_session_token)
					}
				}
			}finally {
				this.reset()
				this.$bus.$emit('on', false)
			}
        }
        // MEM-21-002 구글 OTP 변경 핀번호 확인
        , postOtpPinCheck: async function () {
            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_otp_pin_check
                    , data: {
                        member_number: this.user.member_number
                        , pinnumber: this.pin.toString().replaceAll(',', '')
                    }
                    , type: true
                    })
                    if (result.success) {
                        this.$emit('click')
                    } else {
                        throw result.message
                    }
                } catch (e) {
                    this.$log.console(e)
                    this.$bus.$emit('notify', {type: 'error', message: e})
                } finally {
                    this.clear()
                }
            }
        // MEM-17-001 핀 번호 변경 핀 인증
        , postPinCheck: async function () {
            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_pin_check
                    , data: {
                        member_number: this.user.member_number
                        , pinnumber: this.pin.toString().replaceAll(',', '')
                    }
                    , type: true
                })
                if (result.success) {

					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
                    this.$emit('click', this.pin.toString().replaceAll(',', ''))
                } else {
                    throw result.message
                }
                } catch (e) {
                    this.$log.console(e)
                    this.$bus.$emit('notify', {type: 'error', message: e})
                } finally {
                    this.clear()
                }
            }

            , postPinUpdate: async function () {
            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_pin_update
                    , data: {
                        member_number: this.user.member_number
                        , chg_pinnumber: this.pin.toString().replaceAll(',', '')
                        , chg_pinnumber_confirmation: this.pin_confirm.toString().replaceAll(',', '')
                    }
                    , type: true
                })
                if (result.success) {

					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
                    this.$emit('click', this.pin.toString().replaceAll(',', ''))
                } else {
                    throw result.message
                }
            } catch (e) {
                this.$log.console(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.reset()
            }
        }
        , onOtp: function () {
            // let random = this.getRandomArbitrary(1,100)

			this.$bus.$emit('offPin')
			this.$bus.$emit('to', { name: 'pin_sms'})
            //this.$emit('cancel')
            //this.$router.replace({name: 'pin_sms'})
        }
            //동적라우팅을 통해 휴대폰 인증 핀번호 찾기 페이지로 들어가기 위한 랜덤함수
        , getRandomArbitrary(min, max) {
            return Math.random() * (max - min) + min;
        }

        , postConfirm: async function	(certfc_number = '', certfc_request_key = '', pinnumber = '', mphone_number = ''){
            try{
                this.$bus.$emit('on', true)
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_pin_befor_login
                    , data: {
                        mphone_number: mphone_number
                        , certfc_request_key: certfc_request_key
                        , certfc_number: certfc_number
                        , pinnumber: pinnumber
                        , confirmation_pinnumber: pinnumber
                    }
                    , type: true
                })

                if(result.success){

					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
                    await this.$encodeStorage.setSessionAt(result.data)

                    this.$bus.$emit('setUser', result.data)
                    await this.$router.push({ name: 'main'})
                }else{
                    throw result
                }

            }catch (e) {
                this.$log.console('err : ' + JSON.stringify(e))
                //this.onPin()
                this.$bus.$emit('notify',	{ type: 'error', message: e.message})
                if (e.code === 'E000400062' ||	e.code === 'E000400070') {	//SMS 인증 및 기존등록 핀번호와 같을시 다시 로그인 페이지로 보냄
                    this.$emit('cancel')
                    await this.$router.replace({name : 'login'})	//e.code === 'E000400046' ||
                } else {
                    this.is_confirm = false
                    this.step = 0
                    this.step_confirm = 0
                    this.pin = [false, false, false, false, false, false]
                    this.pin_confirm = [false, false, false, false, false, false]
                }

            }finally {
                this.$bus.$emit('on', false)
            }
        }
    }
    , created() {
      this.$emit('onLoad', this.program)
    }
    , mounted() {
    }
    , watch: {
        step: {
            handler: function (call) {
                if (call > this.max) {
                    this.step = this.max
                }
                if (call < 0) {
                    this.step = 0
                }
            }
        }
        , step_confirm: {
            handler: function (call) {
                if (call > this.max) {
                    this.step_confirm = this.max
                }
                if (call < 0) {
                    this.step_confirm = 0
                }
            }
        }
        , pin: {
            deep: true
            , handler: function (call) {
                if (this.pin_type == 'set' || this.pin_type == 'join' || this.pin_type == 'update' || this.pin_type == 'lost' || this.pin_type == 'pin_modify_before_login') {

                    let is_set = false
                    call.forEach(function (val) {
                        if (val === false) {
                            is_set = false
                            return false
                        } else {
                            is_set = true
                        }
                    })

                    if (is_set) {
                        this.is_confirm = true
                        this.message = "<div class=\"alert-content bg-green\"><i class=\"icon-alert-fail\"></i> <span>" + 'PIN 번호를 한번 더 입력하세요.' + "</span></div>"
                        setTimeout(() => {
                            this.message = ''
                        }, 2000)
                    }
                } else if (this.pin_type == 'pin_login') {

                    let is_set = false
                    call.forEach(function (val) {
                        // this.$log.console(val + ' : ' + typeof val)
                        if (val === false) {
                            is_set = false
                            return false
                        } else {
                            is_set = true
                        }
                    })

                    if (is_set) {
                        this.postPinLogin()
                    }
                } else if (this.pin_type == 'check' || this.pin_type == 'otp_pin_check') {
                    let is_set = false
                    call.forEach(function (val) {
                        // this.$log.console(val + ' : ' + typeof val)
                        if (val === false) {
                            is_set = false
                            return false
                        } else {
                            is_set = true
                        }
                    })

                    if (is_set) {
                        if (this.pin_type == 'otp_pin_check') {
                            this.postOtpPinCheck()
                        } else {
                            this.postPinCheck()
                        }
                    }
                }
            }
        }
        , pin_confirm: {
            deep: true
            , handler: function (call) {
                if (this.is_confirm) {

                    let is_set = false
                    call.forEach(function (val) {
                        if (!val) {
                            is_set = false
                            return false
                        } else {
                            is_set = true
                        }
                    })

                    if (is_set) {
                        if (this.pin.toString() == this.pin_confirm.toString()) {
                            if (this.pin_type === 'update') {
                                this.postPinUpdate()
                            } else if (this.pin_type === 'pin_modify_before_login') {
                                this.postPinBeforLogin()
                            } else if (this.pin_type === 'lost') {
                                //this.$emit('click', this.pin.toString().replaceAll(',', ''), this.pin_confirm.toString().replaceAll(',', ''))
                                this.$emit('lostPinConfirm', this.pin.toString().replaceAll(',', ''))
                            } else {
                                this.postPin()
                            }
                        } else {
                            this.$bus.$emit('notify', {type: 'error', message: 'PIN 번호가 맞지 않습니다. 다시 시도해주세요.'})
                            this.clear()
                        }
                    }
                }
            }
        }
    }
}
</script>

<style>
.password_keyboard {
    background-color: #181A39;
    height: 350px;
}

.word {
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: #24264E;
}

.word.on {
    background-color: #3E47B7;
}

.keyboard td {
    width: 33.33%;
    font-size: 24px;
    height: 50px;
    padding: 15px;
    line-height: 140%;
}

.text_guide {
    font-size: 20px;
}

.keyboard-radius {
    border-radius: 40px 40px 0 0;
}


/*alert*/
.alert-wrap {
    position: absolute;
    z-index: 500;
    top: 40%;
    left: 50%;
    width: 100%;
    max-width: 400px;
    padding: 0 20px;
    transform: translateX(-50%);
    text-align: center;
}

.alert-content {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.7);
}

.alert-content + .alert-content {
    margin-top: 10px;
}

.alert-content i {
    position: relative;
    top: 1px;
    margin-right: 5px;
    color: #fff;
}

.alert-content span {
    color: #fff;
    line-height: 20px;
}

.bg-red {
    background: rgba(255, 18, 18, 0.7) !important;
}

</style>
