<template>
	<div class="header flex-row text-left" >

		<div class="logo flex-column justify-center items-center">
			<a @click="toMain"><img :src="require('@/assets/image/web/ico_logo_header.svg')" /></a>
		</div>

		<div class="nav ">
			<ul>
				<li
					v-for="(gnb, g_index) in list_gnb"
					:key="'gnb_' + g_index"
					:class="{on: gnb.is_on}"
					:data-text="gnb.name"
				><a @click="toGnb(gnb)" class="btn_l">{{ gnb.name }}</a></li>
				<template

					v-if="false"
				>
				<li
					class="dot"
				></li>
				<li

					v-for="(gnb, g2_index) in list_gnb2"
					:key="'gnb2_' + g2_index"
					:class="{on: gnb.is_on}"
					:data-text="gnb.name"
				><a @click="toGnb(gnb)" class="btn_l">{{ gnb.name }}</a></li>
				</template>
			</ul>
		</div>

		<div class="util flex-column justify-center items-center">
			<div class="position-relative">
				<div class="justify-space-between box radius-20 input ">
					<input
					v-model="item_search.search_value"
					type="text"
					class=""
					placeholder="카르텔 전체 검색"
					@keyup.enter="toSearch"

					@focus="is_on_cartel_list = true"
					/>
					<v-icon
						v-if="item_search.search_value"
							@click="item_search.search_value = ''"
							style="margin-left:30px; color: #dadada;"
					>mdi-close-circle</v-icon>
					<v-icon
						class="cursor-pointer" 
						@click="toSearch"
					>mdi-magnify</v-icon>
				</div>
				<a
					class="ico_alram" :class="{on: is_notify_on}"
					@click="$emit('to', { name: 'mafia118'})"
				><img :src="require('@/assets/image/ico_alram2.png')"></a>
				<a
					class="ico_profile"
					@click="$router.push({ name: 'myPage'})"
				><img
					v-if="user.profle_nft_card_img_url"
					:src="user.profle_nft_card_img_url"
					class="object-cover"
				/><img
					v-else
					:src="require('@/assets/image/web/mypage_cartel/@profile_inner.png')"
					class="width-100"
				/></a>


			</div>
		</div>

		<transition
			name="fade"
		>
			<div
				v-if="is_on_cartel_list"
				class="position-fixed-full bg-white pa-24 br-20 "
				style="height: 100vh; z-index: 9999; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>
				<div class="justify-space-between align-center pb-16 under-line">
					<h4 class="color-mafia-blue">전체 카르텔</h4>
					<div
						class="cursor-pointer justify-center align-center"
						@click="is_on_cartel_list = false"
						style="width:20px; height:20px;"
					>
						<img
							:src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" alt="닫기"
							style="width: 14px; height: 14px; display: block;"
						>
					</div>
				</div>
				<div
					class="ma-auto"
					style="width: 1200px; "
				>
					<div
						class="mt-30 input-box pa-10 radius-10 flex-row"
					>
						<v-icon>mdi-magnify</v-icon>
						<input
							v-model="item_search.search_value"
							class="width-100 ml-10"
							placeholder="검색어를 입력하세요"
							maxlength="50"
							@keyup.enter="toSearch"
						>
					</div>
					<ul class="box-list2 mt-20  overflow-y-auto" style="max-height: 600px">
						<li
							v-for="(item, index) in items_total"
							:key="'item_' + index"
							@click="toCartel(item)"
							class="pa-10"
						>
							<div
								class="img-box"
							>
								<img
									:src="item.cartl_img_url"
									@error="$bus.$emit('onErrorCartelProfileImage', $event)"
								/>
							</div>
							<Marquee class="txt-box" :center="true">
								<template
									v-slot:text
								>{{  item.cartl_name }}</template>
							</Marquee>
						</li>
					</ul>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import Marquee from "@/components/Marquee";
	export default {
		name: 'Header'
		,
		components: {Marquee},
		props: ['user']
		, data: function(){
			return {
				item_notify: {
					nrt_note_receive_fg: 'N'
					, nrt_note_receive_count: 0
				}
				, items_gnb: [
					{ name: '카르텔', to: 'cartel'}
					, { name: '런치패드', to: 'launchpad'}
					, { name: 'NFT 컬렉션', to: 'nft'}
					, { name: 'WALLET', to: 'wallet'}
					// , { name: 'DROPS', to: 'drops'}
					, { name: 'STAKING', to: 'staking'}
				]
				, items_gnb2: [
					{ name: '공지사항', to: 'notice'}
					, { name: 'FAQ', to: 'faq'}
					// , { name: '개설요청', to: 'etc'}
				]
				, item_search: {
					search_value: this.$route.params.search_value
				}
				, is_on_cartel_list: false

				, items_total: []
				, item_search_total: {
					page: 1
					, list_cnt: 100
				}
			}
		}
		,computed:{
			is_nft: function(){
			let t = false
				if(this.$route.path.indexOf('nft') > -1){
					t = true
				}
				return t
			}
			, is_wallet: function(){
				let t = false
				if(this.$route.path.indexOf('wallet') > -1){
					t = true
				}
				return t
			}
			, is_staking: function(){
				let t = false
				if(this.$route.path.indexOf('staking') > -1){
					t = true
				}
				return t
			}
			, is_cartel: function(){
				let t = false
				if(this.$route.path == '/' || this.$route.path == '/index' || this.$route.path.indexOf('cartel') > -1){
					t = true
				}
				return t
			}
			, is_bottom: function(){
				let t = true

				if(this.$route.path.indexOf('drops') > -1 || this.is_cartel){
					t = false
				}
				return t
			}
			, is_notify_on: function(){
				let t = false

				this.$log.console('this.item_notify.nrt_note_receive_fg', this.item_notify.nrt_note_receive_fg)
				if(this.item_notify.nrt_note_receive_fg == 'Y'){
					t = true
				}

				return t
			}
			, list_gnb: function(){
				return this.items_gnb.filter((item) => {

					if((this.$route.fullPath).indexOf(item.to) > -1){
						item.is_on = true
					}else{
						item.is_on = false
					}
					return item
				})
			}
			, list_gnb2: function(){
				return this.items_gnb2.filter((item) => {

					if((this.$route.fullPath).indexOf(item.to) > -1){
						item.is_on = true
					}else{
						item.is_on = false
					}
					return item
				})
			}
		}
		, methods: {
			toMain: function(){
				this.$bus.$emit('to', {name: 'main'})
			}
			, getCommNotify: async function(){
				if(!this.user.member_number){
					return false
				}
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_comm_notify
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.item_notify = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					// this.$bus.$emit('notify', { type: 'error', message: e})
					return false
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toGnb: function(gnb){
				this.$bus.$emit('to', { name: gnb.to})
			}
			, toSearch: function(){
				this.$bus.$emit('to', { name: 'mafia047', params: {search_value: this.item_search.search_value}})
			}

			, getCartelList: async function(){
				try{
					this.is_add = false
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_total_list
						, data: {
							member_number: this.user.member_number
							, page_number: this.item_search_total.page
							, pagerecnum: this.item_search_total.list_cnt
						}
						, type: true
					})

					if(result.success){
						this.items_total = result.data.cartl_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_add = true
				}
			}
			, toCartel: function(item){
				this.$bus.$emit('to', { name: 'mafia049', path: '/cartel/mafia049/' + item.cartl_number, params: { idx: item.cartl_number}})
				this.is_on_cartel_list = false
			}
		}
		, created() {
			if(this.user.member_number){
				this.getCommNotify()
				this.getCartelList()
			}
		}
		, watch:{
			$route(to){
				// this.$log.console('header watch to', to)
				this.item_search.search = to.params.search_value
				this.is_on_cartel_list = false
			}
		}
	}
</script>

<style>
	.header {
		width: 100%;
		height: 80px;
		/* overflow-x: hidden; */
		background-color: white;
		padding: 0 30px;
		filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
	}
	.header > div{

	}

	.header .logo{
		/* padding: 10px; */
		/* flex-basis: 240px; */
		margin-right: 50px;
	}
	.header .nav {
		height: 100%;
		flex: 1;
		width: 700px
	}

	.header .nav .dot{
		background-color: #dadada;
		border-radius: 50px;
		width: 2px; height: 2px; padding: 2px;
		margin: 4px 15px;
	}

	.header .nav ul{
		height: 100%;
	}
	.header .nav ul li{
		display: inline-flex;
		padding: 0 15px;
		height: 100%;
	}

	.header .nav ul li:hover,
	.header .nav ul li.on {
		border-bottom: 2px solid #333;
		overflow: hidden;
		text-shadow: 0.3px 0px #333;
	}
	.header .nav ul li.on a{
		color: var(--dark-Gray02);
	}
	.header .nav ul li:hover a{
		color: var(--dark-Gray02);
	}

	.header .nav ul li a{
		font-size: 16px;
		line-height: 80px;
		font-weight: 500;
		color: var(--gray02);
	}

	.header .util {
		text-align: right;
		/* flex-basis: 350px; */
		/* padding: 10px */
	}
	.header .util > div { 
		display: flex;
		align-items: center;
		gap: 16px;
	}

	.header .util .input {
		width: 300px;
		border: 1px solid var(--light-Gray02);
		border-radius: 50px 50px;
		padding: 8px 10px 8px 20px;
		/* margin-right: 10px; */
	}
	.header .util input::placeholder{
		color: var(--gray01);
	}

	.header .util .ico_alram {
		position: relative;
		/* margin-right: 10px; */
		padding: 8px;
	}
	.header .util .ico_alram img { width: 22px; height: 22px;}

	.header .util .ico_alram.on:after { content: ''; position: absolute; right: 4px; top: 3px; width: 5px; height: 5px; border-radius: .5rem; background-color: var(--red); }

	.header .util .ico_profile{
		display: inline-block;
		width: 40px; height: 40px;
		border-radius: 100%; border: 1px solid #ddd;
		overflow: hidden;
		/* margin-right: 10px; */
		padding: 0px;
		background-color: #eee;
		text-align: center;
	}
	.header .util .ico_profile img {
		object-fit: cover;
		width: 100%;
	}
	.header .box-list2 {
		display: grid;
		grid-template-columns: repeat(auto-fill,minmax(120px, 1fr));
		grid-gap: 0 10px;
		gap: 40px 16px;
	}
	.header .box-list2 li {
		--shadow-gap: -8px;
		grid-auto-flow: row;
		grid-template-columns: 1fr;
		grid-gap: 14px;
		gap: 14px;
		cursor: pointer;
	}

	.header .box-list2 .img-box  {
		width: 120px; height: 120px;
		-webkit-mask-image: url(../../assets/image/web/box_mask_120.png);
		mask-image: url(../../assets/image/web/box_mask_120.png);
		mask-repeat: no-repeat;
		mask-position: center center;
	}


	.header .box-list2 .img-box img  {
		width: 100%; height: 100%; object-fit: cover;
	}

	.header .box-list2 li:hover {
		background-color: #eee;
		border-radius: 10px;
		-webkit-transition: background-color 0.3s ease;
		-ms-transition: background-color 0.3s ease;
		transition: background-color 0.3s ease;
	}

	.header .box-list2 li:hover .img-box  {
		display: flex; flex-direction: column; align-items: center; align-content: center;
	}

	.header .box-list2 li:hover .img-box img{
		transform: scale(110%);
		transition: all .3s
	}

	.header .box-list2 .txt-box {
		margin-top: 10px; font-size: 15px; text-align: center; font-weight: 500;
		height: 40px; overflow: hidden;
		text-overflow: ellipsis; word-break: break-all; -webkit-line-clamp: 2
	}

	.header .box-list::-webkit-scrollbar{width: 4px; background:#e9e9e9; border-radius: 2px; height: 100%;}
	.header .box-list::-webkit-scrollbar-thumb{width: 4px; background: #bdbdbd; border-radius: 2px;}
	.header .box-list::-webkit-scrollbar-track{width: 4px; background: #e9e9e9; border-radius: 2px;}

	.fade-enter-active {
		transition: all .3s ease;
	}
	.fade-leave-active {
		transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.fade-enter, .fade-leave-to
		/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateY(-10px);
		opacity: 0;
	}
</style>