<template>
	<div
		class="wrap bg-auth"
	>
		<div
			v-if="is_loading"
			class="loading_wrap"
		>
			<div class="loading_bg"></div>
			<div class="dot_container">
				<div class="lds-ellipsis">
					<div><span class="hide">dot1</span></div>
					<div><span class="hide">dot2</span></div>
					<div><span class="hide">dot3</span></div>
					<div><span class="hide">dot4</span></div>
				</div>
			</div>
		</div>

		<div
			class="wrap-auth"
		>
			<LayoutAuth
				class="auth-content"
			></LayoutAuth>
			<div
				class="wrap-contents"
			>
				<div
					class="contents position-relative"
				>
					<div
						class="full-height pb-30 bg-mafia-dark flex-column position-relative"
					>
						<div class=" flex-column justify-center">
							<div class="pt-150">
								<div><img :src="require('@/assets/image/web/ico_logo_large.svg')" /></div>
								<div class="mt-20">
									<img :src="require('@/assets/image/web/ico_logo_word.svg')" />
								</div>
								<div class="mt-20">문화예술 전용 커뮤니티 <b>마피아 프로젝트</b></div>
							</div>
						</div>

						<div class="full-height overflow-y-auto flex-column justify-center">
							<div
								v-if="is_try"
							>
								<div class="text_guide pt-20">
									<p class="size-px-24">네트워크가 원활하지 않습니다.</p>
								</div>
								<div class="text_guide_desc pt-15">
									<p>문제가 지속될 경우 관리자에게 문의하세요.</p>
								</div>
							</div>
						</div>

						<div class="pa-20 pb-80">
							<div
								v-if="is_try"
								class="intro_btn_wrap"
							>
								<div class="btn_area">
									<button
										@click="toTry"
										class="btn_l btn_fill_blue width-100 pa-10 radius-20"
									>재시도</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import LayoutAuth from "@/view/Layout/LayoutAuth";
	export default{
		name: 'Intro'
		, props: ['user']
		, components: {LayoutAuth  }
		,data: function(){
			return {
				program: {
					name: 'intro'
					, title: 'intro'
					, not_header: true
					, not_footer: true
				}
				, is_try: false
				, is_loading: true
			}	
		}
		, methods: {
			onLoad: function(){
				this.is_loading = true
				setTimeout( () => {
					this.is_loading = false
					this.is_try = true
				}, 1000)
			}
			, toTry: function(){
				this.$bus.$emit('logout')
			}
		}
		
		,mounted:  async function(){
			this.$log.console(' intro created !!')
			this.$bus.$emit('onLoad', this.program)
			this.onLoad()
		}
	}
	
</script>



<style>

@keyframes spinner {
	to {transform: rotate(-360deg);}
}

.spin {
	animation: spinner 2s linear infinite;
}
</style>