<template>
	<div
		class="wrap-bottom bg-bottom"
		>
		<div
			class="container-bottom"
		>
			<div class="logo-bottom">
				<img :src="require('@/assets/image/web/ico_logo_footer.svg')" />
			</div>

			<div class="box-ask mt-20">
				<div>파트너쉽 및 제휴문의:
					<a :href="'mailto:metamafi@metamafi.com'" class="mail"> metamafi@metamafi.com</a>
				</div>
				<div class="mt-5">투자 및 세일 문의:
					<a :href="'mailto:sales@metamafi.com'" class="mail"> sales@metamafi.com</a>
				</div>

				<div class="box-etc">
					<a :href="'https://mafiproject.com/index.html'" target="_blank">
						<span>Meta Mafi 바로가기</span>
					</a>
					|
					<a :href="'/auth/personal'" target="_blank">
						<span>개인정보처리방침</span>
					</a>
				</div>
			</div>

			<hr class="mt-30" />

			<div class="copyright">
				Copyrights 2023 © MetaMAFI Ltd. All Rights Reserved.

				<div class="box-sns">
					<ul>
						<li>
							<a :href="'https://t.me/metamafia'" target="_blank">
								<img :src="require('@/assets/image/web/index_cartel/ico_sns01.svg')">
							</a>
						</li>
						<li>
							<a :href="'https://www.instagram.com/metamafia_official/'" target="_blank">
								<img :src="require('@/assets/image/web/index_cartel/ico_sns02.svg')">
							</a>
						</li>
						<li>
							<a :href="'https://twitter.com/ProjectMAFIANFT'" target="_blank">
								<img :src="require('@/assets/image/web/index_cartel/ico_sns03.svg')">
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Bottom'
		, props: ['program']
		, data: function(){
			return {

			}
		}
		,computed:{
			is_nft: function(){
				let t = false
				if(this.$route.path.indexOf('nft') > 0 || this.$route.path.indexOf('wallet') > 0 || this.$route.path.indexOf('staking') > 0){
					t = true
				}
				return t
			}
			,is_drops: function(){
				let t = false
				if(this.$route.path.indexOf('drops') > 0){
					t = true
				}
				return t
			}
			,is_cartel: function(){
				let t = false

				if(this.$route.path == '/' || this.$route.path == '/index' || this.$route.path.indexOf('cartel') > 0){
					t = true
				}
				return t
			}
		}
		, methods: {

			to: function(type){
				if(this.$route.name != type){
					this.$emit('to', { name: type})
				}
			}
		}
		,created() {
			//this.$log.console('bottom', this.program)
		}
	}
</script>

<style>
	.bg-bottom {
		background-color: #f7f7f7;
		border-top:1px solid #e9e9e9;
	}
	.container-bottom {
		/* width: 1200px; */
		margin: 0 auto;
		padding: 40px 30px 0 30px;
		text-align: left !important;
		position: relative;
	}

	.container-bottom .box-ask {
		position: relative;
		color: #888;
		font-size: 12px;
	}
	.container-bottom .box-ask div{
		display: flex;
		gap: 4px;
	}
	.container-bottom .box-ask a {
		color: #888;
	}
	.container-bottom .box-ask .box-etc{
		position: absolute;
		right: 0; bottom: 0px;
		color: #dadada;
		font-size: 12px; font-weight: 500;
		display: flex;
		gap: 10px;
	}
	.container-bottom .box-ask .box-etc span{
		color: #434343;
	}

	.container-bottom hr {
		border-top: 1px solid #DADADA
	}

	.container-bottom .copyright{
		padding: 30px 0px 40px 0;
		color: #888;
		font-size: 12px;
		display: flex; justify-content: space-between; align-items: center;
	}

	.container-bottom .copyright .box-sns ul {
		display: flex;
		gap: 20px;
	}
</style>