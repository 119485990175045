<template>

	<div class="position-relative">

		<div
			ref="box-slide"
			class="wrap-banner flex-row position-relative"
			style="white-space: nowrap; z-index: 9"
		>
			<ul
				class="flex-1 flex-row "
				style="flex-wrap: nowrap; height: fit-content;"
			>
				<li
					ref="item-slide"
					v-for="(item, index) in items"
					:key="'item_' + index"
					style="height: 100%; overflow: hidden"
					:style="'width: ' + screen_width + 'px'"
					class="flex-column justify-center items-center"
					:class="{ 'cursor-pointer' : item.path }"
					@click="$emit('click', item)"
				>
					<img
						:src="item.url"
						:alt="item.name"
						@click="$emit('click', item)"
						style="object-fit: cover; "
					/>
				</li>
			</ul>
		</div>

		<div
			class="position-absolute-full flex-column justify-end"
		>
			<ul class=" text-center mb-10 position-relative" style="z-index: 10">
				<li
					v-for="i in items.length"
					:key="'dot_' + i"
					class="inline-block mr-10 cursor-pointer"
					@click="toPage(i)"
				>
					<v-icon
						v-if="page == i"
						small
						class="color-white"
					>mdi mdi-circle</v-icon>
					<v-icon
						v-else
						small
						class="color-white"
					>mdi mdi-circle-outline</v-icon>
				</li>
			</ul>
		</div>
	</div>


</template>

<script>
export default {
	name: 'CSlide'
	, props: ['items']
	, data: function(){
		return {
			page: 1
			, max: this.items.length
			, screen_width: 0
			, time: 5000
			, st: ''
		}
	}

	, methods: {
		init: function(){
			this.screen_width = document.body.clientWidth
			this.st = setTimeout( () => {
				this.toSlide()
			}, this.time)
		}
		, toSlide: function(){
			if(this.page >= this.max){
				this.page = 0
			}
			this.screen_width = document.body.clientWidth

			let to = this.screen_width * this.page
			// alert(to)
			if(!this.$route.params.idx){
				this.$refs["box-slide"].scrollTo( { left: to, behavior: "smooth"})
				this.page++
				this.st = setTimeout(() => {
					this.toSlide()
				}, this.time)
			}
		}
		, toPage: function(page){
			this.page = page - 1
			clearTimeout(this.st)
			this.st = setTimeout(() => {
				this.toSlide()
			}, 0)
		}
	}
	, created() {

		setTimeout( () => {
			this.init()
		}, 100)

	}
}
</script>

<style>
	.inline-block { display: inline-block }

	.wrap-banner::-webkit-scrollbar{
		width: 0;
	}
</style>