<template>
	<mobile
		v-if="is_mode"
	></mobile>
	<version
		v-else-if="is_version"
		:type="this.item_app_info.app_execu_code"

		@cancel="is_version = false"
	></version>
	<Layout
		v-else-if="is_view"
		:Base64="Base64"
		:metaInfo="metaInfo"
		:TOKEN="TOKEN"
		:user="user"
		:codes="codes"
		:certfc="certfc"
	/>
	<Intro
		v-else
		:user="user"
	></Intro>
</template>

<style lang="css" src="@/assets/css/reset.css"></style>
<style lang="css" src="@/assets/css/icomoon.css"></style>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="css" src="@/assets/css/style.css"></style>
<style lang="css" src="@/assets/css/base.css"></style>

<script>

import { metaInfo } from '@/resources/config/metainfo'
import { codes } from '@/resources/config/codes'

import { Base64 } from 'js-base64'

import Layout from '@/view/Layout/Layout.vue'

import {sample} from "@/resources/sample/sample";

import Intro from "@/view/Main/Intro"

import { v4 as uuidvr4 } from 'uuid'
import version from "@/view/Auth/version";
import Mobile from "@/view/Auth/mobile";
import {encodeStorage} from "@/resources/storage/localStorage";

export default {

	name: 'App'
	,components: {Mobile, version, Layout, Intro }
	,data: function(){
		return {
			metaInfo: metaInfo
			, Base64: Base64
			, codes: codes
			, TOKEN: ''
			, user: {}
			, certfc: {
				session_token: ''
			}
			, sample: sample
			, AT: ''
			, is_notify: false
			, item_app_info: {
				update_code: ''
			}
			, is_version_match: true
			, is_version: false
			, is_view_: false
			, is_error: false
			, item_error: {}
		}
	}
	, computed: {
		is_view: function(){
			let t = false

			if((this.is_version_match && this.user.member_number) || (this.certfc.session_token && this.$route.fullPath.indexOf('/auth/') > -1) || this.is_view_ || this.$route.name == 'EventDetail'){
				t = true
			}
			// this.$log.console('is_view', this.$route.name,  this.is_version_match, this.user.member_number, this.certfc.session_token, this.$route.fullPath.indexOf('/auth/'), this.is_view_, `t: ${ t }`)
			return t
		}
		, is_mode: function(){
			let t = false

			let is_mobile = this.$common.isMobile()
			let location = window.location.href
			let domain = process.env.VUE_APP_DOMAIN
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV
			let domain_stg = process.env.VUE_APP_DOMAIN_STG
			let domain_local = process.env.VUE_APP_DOMAIN_LOCAL
			if(is_mobile == 'CM00500003' && (location.indexOf(domain) > -1 || location.indexOf(domain_dev) > -1 || location.indexOf(domain_stg) > -1 || location.indexOf(domain_local) > -1)){
				t = true
			}
			this.$log.console('is_mode', is_mobile, location, t)
			return t
		}
	}
	, methods: {
		postAutoLogin: async function(){
			this.$log.console('App postAutoLogin')
			try {

				let login_data = this.$encodeStorage.getItem('login_info')
				let pin_token = this.$encodeStorage.getItem('pin_token')

				if(!login_data || !pin_token){
					await this.$router.push({ name: 'login'})
					return false
				}else {
					await this.$router.push({ name: 'login', params: { auto: 'true'}})
					return false
				}
			} catch (e) {
				this.$log.console('auto login fail !!')
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
				await this.$router.push({ name: 'login'})
			}
		}

		, postDeviceToken: async function(token){

			this.$log.console('postDeviceToken')
			/*
			for(let [k, v] of Object.entries(token)){
				//alert(k + ':' + v)
				// this.$log.console(k, v)
			}

			 */
			try {

				const result = await this.$Request({
					method: 'POST'
					, url: this.$api_url.api_path.post_device_token
					, data: token
					, type: true
				})
				if (result.success) {

					this.item_app_info = result.data

					/*
					for(let [k, v] of Object.entries(this.item_app_info)){
						//alert(k + ':' + v)
						this.$log.console(k, v)
					}

					 */
					try{
						this.certfc = result.data
						await this.$encodeStorage.setSessionCertfc(this.certfc)
						await this.$encodeStorage.setSessionVersion(token.app_version)
						await this.doLogin()
					}catch (e){
						this.$log.console(e)
					}
				} else {
					throw result.message
				}
			} catch (e) {
				this.$log.console(e)
				this.is_view_ = true
				//await this.$router.push({ name: 'mafia009', params: { type: 'third', msg: e}})
			}
		}

		, doLogin: async function(){

			let AT = this.$encodeStorage.getSessionAt()
			let is_intro = localStorage.getItem('is_intro')

			if(!AT){
				if(!is_intro) {
					if(document.location.href.indexOf('auth') > -1 || document.location.href.indexOf('sample/reset') > -1){
						// this.$log.console('this.$router.currentRoute.fullPath', this.$router.currentRoute.fullPath)
					}else {
						await this.$router.push({name: 'intro'})
					}
				}else{
					this.$log.console('app location', document.location.href)

					if(document.location.href.indexOf('auth') > -1 || document.location.href.indexOf('sample/reset') > -1 || document.location.href.indexOf('CartelDetail') > -1){
						// this.$log.console('this.$router.currentRoute.fullPath', this.$router.currentRoute.fullPath)
					}else{
						await this.postAutoLogin()
					}
				}

			}else{
				if(this.s_notify){
					await this.$router.push({ name: 'mafia118'})
				}
			}
		}

		, getDeviceInfo: async function(){

			// 디바이스 정보
			try{

				let uuid = this.$encodeStorage.getItem('_uuid')

				if(!uuid){
					uuid = uuidvr4()
					await this.$encodeStorage.setItem('_uuid', uuid)
				}

				let device_info = {
					"conn_div_code": this.$common.isMobile(),
					"uniq_device_id": uuid,
					"mobile_opsys_code": "AP00100001",
					"opsys_version": "",
					"push_id": '',
					"app_version": "1.0.12",
					"conn_ip": await this.$common.getIp()
				}
				await this.postDeviceToken(device_info)
			}catch (e){
				this.$log.console('디바이스 정보', e)
			}
		}

		/*
		* user - 소셜 인증 정보
		* member_number - 소셜 인증 회원 번호
		* session_token - 소셜 인증 토큰
		* */
		, setUser: async function(user){

			//this.$log.console('setuser', user)
			// this.user = user
			//this.$log.console('this.user', this.user)
			await this.getUserInfo(user)

			this.$bus.$emit('offPin')
			// this.$bus.$emit('to', { name: 'main'})

		}

		/*
		* 회원 정보 조회
		* */
		, getUserInfo: async function(user){
			let session_token = encodeStorage.getSessionToken()
			if(!session_token){
				this.$bus.$emit('logout')
			}else{

				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_my_info
						, data: {
							member_number: user.member_number
						}
						, type: true
					})
					if(result.success){
						this.$log.console(result.data)
						let member_number = user.member_number
						let access_token = user.access_token
						let at = result.data
						at.member_number = member_number
						at.access_token = access_token

						this.user = at
						await this.$encodeStorage.setSessionAt(at)

						await this.getAppVersionInfo()
					}else{
						throw result.message
					}
				}catch (e){
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})

					this.$bus.$emit('logout')
				}
			}
		}

		, getCommonCode: async function(group_id){
			this.$log.console('on bus getCommonCode')
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_common_code
					, data: {
						member_number: this.user.member_number
						, common_code_group_id: group_id
					}
					, type: true
				})
				if(result.success){
					this.$log.console(result.data)
					return result.data
				}else{
					throw result.message
				}
			}catch (e){
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, getAppVersionInfo: async function(){
			try{
				const result = this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_app_version_info
					, data: {
						member_number: this.user.member_number
						, app_version: process.env.VUE_APP_VERSION
					}
					, type: true
				})

				if(result.success){
					this.item_app_info = result.data
					if(this.item_app_info.update_code != 'AP00500001') {
						try{
							this.is_version = true

						}catch (e) {
							throw result.data.update_message
						}
					}
				}else{
					throw result.message
				}
			}catch (e){
				this.$log.console(e)
				// this.$bus.$emit('notify', { type: 'error', message: e})
				this.is_version_match = true
			}
		}

		, setSessionToken: async function(token){
			let t = await this.$encodeStorage.getSessionCertfc()

			if(t){
				t.session_token = token
				await this.$encodeStorage.setSessionToken(token)
				this.$log.console('setSessionToken', t)
				await this.$encodeStorage.setSessionCertfc(t)
			}
		}

		, appPushNotify: function(){
			try {
				this.$log.console('window.notify')
				this.is_notify = true
				let at = this.$encodeStorage.getSessionAt()
				if (at) {
					this.$router.push({name: 'mafia118'})
				} else {
					this.postAutoLogin()
				}
			}catch (e){
				this.$log.console(e)
			}
		}
		, setBoardConfigSort: async function(items_board_config){
			items_board_config.sort(function(a, b){
				let A = a.board_bookmark_fg
				let B = b.board_bookmark_fg

				if(A == 'Y' && B == 'N'){
					return -1
				}
				if(A == 'N' && B == 'Y'){
					return 1
				}

				return 0
			})
			items_board_config.sort(function(a, b){
				let A = a.board_type_code
				let B = b.board_type_code

				if(A < B){
					return -1
				}
				if(A > B){
					return 1
				}

				return 0
			})
		}
	}
	, async created() {

		let location = window.location.href
		let domain = process.env.VUE_APP_DOMAIN
		let domain_m = process.env.VUE_APP_DOMAIN_M
		let domain_dev_m = process.env.VUE_APP_DOMAIN_DEV_M

		if(location.indexOf(domain) > -1) {
			window.document.location.href = domain_m
		}else{
			window.document.location.href = domain_dev_m
		}

		/*
		this.$log.console('in App')
		let at = this.$encodeStorage.getSessionAt()
		let session_token = this.$encodeStorage.getSessionToken()

		// 앱 푸시 노티
		window.notify = () => {
			this.appPushNotify(at)
		}

		if(at && session_token){
			await this.setUser(at)
			//this.is_view = true
		}else{
			await this.getDeviceInfo()
		}

		this.$bus.$on('onError', (error) => {
			this.is_error = true
			this.item_error = error
		})

		this.$bus.$on('onUnauthorized', () => {
			console.log('onUnauthorized')
			this.$bus.$emit('onPin', 'pin_login')
		})


		this.$bus.$on('getUserInfo', async () => {
			await this.getUserInfo()
		})
		this.$bus.$on('getDeviceToken', async () => {
			await this.getDeviceInfo()
		})

		this.$bus.$on('setUser', async (user) => {
			await this.setUser(user)
		})

		this.$bus.$on('getCommonCode', async (group_id) => {
			return await this.getCommonCode(group_id)
		})

		this.$bus.$on('setBoardConfigSort', (items_board_config) => {
			return this.setBoardConfigSort(items_board_config)
		})

		this.$bus.$on('not_pause', () => {
			this.$log.console('not pause')
			try{
				window.android.postMessage('not_pause');
			}catch (e){
				this.$log.console(e)
			}
		})

		this.$bus.$on('log', ({ e }) => {
			this.$log.console(e)
		})

		this.$bus.$on('logout', async () => {

			this.user = {}
			await this.$encodeStorage.removeItem('login_info')
			await this.$encodeStorage.removeSessionAt()
			await this.$encodeStorage.removeSessionCertfc()
			await this.$encodeStorage.removeItem('pin_token')
			await sessionStorage.clear()
			await this.getDeviceInfo()
			this.$bus.$emit('to', { name: 'login'})
		})

		 */
	}
};
</script>

<style>
	.cartel_write { right: 0!important; bottom: 0 !important;}
	.cartel_write_btn { width: 6rem !important; height: 6rem !important;}
	.layer-popup { position: fixed; left: 0; top: 0; width: 100%; height: 100%; overflow: auto; background-color: white; z-index: 9998}

</style>