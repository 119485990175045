<template>
	<div class="bg_dark" style="background-color: #0f1020 !important;">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character3">
							<div class="text_guide pt-20">
								<h4>{{  text_title }}</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p>{{  text_content }}</p>
							</div>
						</div>

					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button
								@click="toNext"
								class="btn_l btn_fill_blue"
							>{{ text_button }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia009'
	, props: ['user']
	,data: function(){
		return {
			program: {
				name: '가입 계정 확인'
				, not_header: true
				, not_footer: true
			}
			, type: this.$route.params.type
			, msg: this.$route.params.msg
			, error: sessionStorage.getItem('Error')
		}
	}
	, computed: {
		text_title: function(){
			let t = ''
			switch (this.error){
				case '401':
					t = '토큰 만료'
					break
				case '400':
					t = '요청 처리 불가'
					break
				case '500':
					t = '요청 처리 오류'
					break
				default:
					t = this.error
					break
			}
			return t
		}
		, text_content: function(){
			let t = ''
			switch (this.error){
				case '401':
					t = '로그인 후 다시 이용해주세요'
					break
				case '400':
					t = '문제가 지속되면 관리자에게 문의하세요'
					break
				case '500':
					t = '요청 처리중 문제가 발생하였습니다'
					break
				default:
					t = this.error
					break
			}
			return t
		}
		, text_button: function(){

			let t = ''
			switch (this.error){
				case '401':
					t = '확인'
					break
				case '400':
					t = '재시도'
					break
				case '500':
					t = '재시도'
					break
				default:
					t = this.error
					break
			}
			return t
		}
	}
	,methods: {
		toNext: async function(){

			let error = sessionStorage.getItem('Error')
			try {
				this.$bus.$emit('on', true)
			}catch (e){
				this.$log.console(e)
			}finally {
				sessionStorage.clear()
				switch (error){
					case '401':

						await this.$bus.$emit('to', { name: 'login'})
						break
					default:

						if(!this.user){
							await this.$bus.$emit('to', { name: 'login'})
						}else{
							await this.$bus.$emit('to', { name: 'login'})
						}
						break
				}

				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>